const navigator = [
  {
    name: 'people',
    subNav: ['faculty', 'staff', 'alumni'], // removed students and post-doc
  },
  {
    name: 'programs',
    subNav: ['ma-programs', 'phd-programs', 'international-students', 'funding'],  // removed lic
  },
  {
    name: 'courses',
    subNav: ['degree-requirements', 'current-courses'],
  },
  {
    name: 'research',
  },
  {
    name: 'announcement',
    subNav: ['news', 'events', 'previous-newsletters']
  },
  {
    name: 'resources',
    subNav: ['online-courses', 'cldc', 'corpora', 'ntue', 'tol', 'facilities', 'rogil'],
  },
  {
    name: 'about',
    subNav: ['location', 'contact-info', 'job-openings', 'activities'],
  },
  {
    name: 'languages',
    subNav: ['zh_tw', 'en'],
  },
];

export default navigator;
