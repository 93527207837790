import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useTranslation,
  withTranslation,
} from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import {
  SectionContainer,
  Title,
  TextTitle,
  TextOnImage,
  GridSideBySide,
  ImageTitle,
  Text,
  Time,
  Spinner,
} from '../UI';
import { LanguageContext } from '../../App';
import InternalLink from '../Links/InternalLink';
import Discussion from '../../imgs/home/discussion.jpg';
import getDayOfWeek from '../../utility';
import baseURL from '../../config/url';

const EventList = styled.div`
  display: grid;
  margin-bottom: 30px;
  padding-left: 15px;
  .date {
    grid-area: date;
  }
  .time {
    grid-area: time;
  }
  .content {
    grid-area: content;
  }
  grid-template-columns: 1fr 5fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-template-areas: 
    "date time"
    "date content"
`;

const Date = styled.div`
  border: 3px solid ${props => props.theme.third};
  color: ${props => props.theme.third};
  width: 70px;
  height: 70px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const EventContent = styled.div`
  p {
    margin: 0;
  }
`;

const EventsImage = styled.img`
   width: 350px;
   @media screen and (max-width: ${props => props.theme.maxWidth}) {
     width: 100vw;
     margin-right: 0;
   }
 `;

const HomeEvents = () => {
  const { t } = useTranslation();
  const { lg } = useContext(LanguageContext);

  const GET_HOMEEVENTS = gql`
  query EventPosts {
    eventsPosts(sort:"start_date:desc") {
      data {
        id
        attributes {
          annoucement_date
          start_date
          time
          location_${lg}
          title_${lg}
          attachment_${lg}
        }
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_HOMEEVENTS);
  // console.log(data)

  if (error) { return `Error! ${error.message}`; }

  const getInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <EventList key={el.id}>
        <Date className="date">
          <span>{`${el.attributes.start_date.split('-')[1]}/${el.attributes.start_date.split('-')[2]}`}</span>
          <span>{getDayOfWeek({ t, day: el.attributes.start_date })}</span>
        </Date>
        <Time className="time">{el.attributes.time}</Time>
        <EventContent className="content">
          <Text>{el['attributes'][`location_${lg}`]}</Text>
          <TextTitle>{el['attributes'][`title_${lg}`]}</TextTitle>
          <Text>
            <ReactMarkdown
              source={el['attributes'][`attachment_${lg}`]}
              renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
            />
          </Text>
        </EventContent>
      </EventList>
    )) : null;
    return info;
  };

  return (
    <SectionContainer style={{ marginTop: '150px' }}>
      <GridSideBySide
        justifyContent="flex-start"
        mobileAlignItems="flex-start"
        mobileMarginLeft="0"
      >
        <ImageTitle>
          <TextOnImage>
            <Title>
              {t('titles.announcement.events.upcoming_events')}
            </Title>
          </TextOnImage>
          <EventsImage src={Discussion} />
        </ImageTitle>
        <div>
          {loading ? <Spinner /> : getInfo(data.eventsPosts.data.slice(0, 5))}
        </div>
      </GridSideBySide>
      <InternalLink
        style={{
          paddingLeft: 0,
          float: 'right',
        }}
        link="/announcement/events"
        linkText={t('titles.announcement.read_more')}
      >
        {t('titles.announcement.read_more')}
      </InternalLink>
    </SectionContainer>
  );
};

export default withTranslation('events')(HomeEvents);
