import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  ContentContainer,
  Title,
  Label,
  Text,
  CentralBarLine,
} from '../../UI';
import NewslettersBanner from '../../../imgs/banners/newsletters.jpg';
import PDFLink from '../../Links/PDFLink';

const Newsletters = () => {
  const { t } = useTranslation();

  const getSource = (fileName) => {
    return require('../../../pdf/announcement/newsletters/' + fileName);
  };

  const allNewsletters = t('newsletters:newsletters.list', { returnObjects: true });

  const getInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <ContentContainer style={{ padding: '30px 30px' }}>
        <Label>{t('newsletters:newsletters.titles.published_year')}</Label>
        {el.published_year}
        <CentralBarLine />
        <Label>{t('newsletters:newsletters.titles.issue_number')}</Label>
        {el.issue_number}
        <PDFLink
          link={getSource(el.pdf_link)}
        />
      </ContentContainer>
    )) : null;
    return info;
  };

  const NewsletterInfo = getInfo(allNewsletters);

  return (
    <>
      <BannerContainer>
        <Banner src={NewslettersBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('newsletters:newsletters.titles.previous_newsletters')}</Title>
        <Text>{t('newsletters:newsletters.overview')}</Text>
        {NewsletterInfo}
      </SectionContainer>
    </>
  );
};

export default Newsletters;