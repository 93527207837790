const theme = {
  primary: '#182624',
  secondary: '#e3e9e8',
  third: '#446a65',
  gray: '#e8e8e8',
  darkgray: '#999999',
  lightgray: '#ececec',
  text: '#182624',
  normalFont: '1.6rem',
  maxWidth: '1023px',
  pagePadding: '30px',
  mobilePagePadding: '20px',
  navPadding: '15px',
  transitionDuration: '0.3s',
  sectionMargin: '50px',
};

export default theme;
