import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import {
  SectionContainer,
  TextOnImage,
  Title,
  GridSideBySide,
  ImageTitle,
  TextTitle,
  Text,
  Time,
  Icon,
  Spinner,
} from '../UI';
import { LanguageContext } from '../../App';
import pinIcon from '../../imgs/icons/pin.png';
import NewsPhoto from '../../imgs/home/2018_xmas_party.jpg';
import InternalLink from '../Links/InternalLink';
import getDayOfWeek from '../../utility';
import baseURL from '../../config/url';

const NewsList = styled.div`
  margin-bottom: 30px;
  padding-left: 15px;
  p {
    margin: 0;
  }
`;

const NewsImage = styled.img`
  width: 350px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    width: 100vw;
    margin-right: 0;
  }
`;

const HomeNews = () => {
  const { t } = useTranslation();
  const { lg } = useContext(LanguageContext);

  const GET_HOMENEWS = gql`
  query {
    newsPosts(sort:"annoucement_date:desc") {
      data {
      id
      attributes {
        annoucement_date
        title_${lg}
        top     
        attachment_${lg}
        }
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_HOMENEWS);

  if (error) { return `Error! ${error.message}`; }

  const getInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <NewsList>
        <Text>
          {el.attributes.top ? <Icon src={pinIcon} /> : null}
          <Time>{`${el.attributes.annoucement_date} (${getDayOfWeek({ t, day: el.attributes.annoucement_date })})`}</Time>
        </Text>
        <TextTitle>
          {el.attributes[`title_${lg}`]}
          <ReactMarkdown
            source={el.attributes[`attachment_${lg}`]}
            renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
          />
        </TextTitle>
      </NewsList>
    )) : null;
    return info;
  };

  const topNewsInfo = data && getInfo(data.newsPosts.data.filter(post => post.top === true));
  const latestNewsInfo = data && getInfo(data.newsPosts.data.filter(post => !post.top))
    .slice(0, topNewsInfo.length + 1);

  return (
    <SectionContainer>
      <GridSideBySide
        justifyContent="flex-start"
        mobileAlignItems="flex-start"
        mobileMarginLeft="0"
      >
        <ImageTitle>
          <TextOnImage>
            <Title>
              {t('titles.announcement.news.latest_news')}
            </Title>
          </TextOnImage>
          <NewsImage src={NewsPhoto} />
        </ImageTitle>
        <div>
          {loading ? <Spinner /> : topNewsInfo}
          {loading ? <Spinner /> : latestNewsInfo}
        </div>
      </GridSideBySide>
      <InternalLink
        style={{
          paddingLeft: 0,
          float: 'right',
          display: 'block',
        }}
        link="/announcement/news"
        linkText={t('titles.announcement.read_more')}
      />
    </SectionContainer>
  );
};

export default HomeNews;
