import styled from 'styled-components';

export const LogoImage = styled.img`
  width: 80px;
  margin: 0 20px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    width: 70px;
    margin-left: 0;
    margin-right: 15px;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  width: 280px;
  height: 400px;
  background-size: 360px auto;
  background-repeat: no-repeat;
  z-index: -1;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    background-size: 280px auto;
  }
`;

export const BackgroundImageTop = styled(BackgroundImage)`
  border-left: 5px solid ${props => props.theme.third};
  top: calc(100vw * 0.3125);
  left: -10%;
  background-image: url(/bgtop.png);  
  background-position: right;
  ${props => props.noBanner && `
    top: 0;
  `}
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    top: 150px;
    left: -30%;
  }
`;

export const BackgroundImageBottom = styled(BackgroundImage)`
  border-right: 5px solid ${props => props.theme.third};
  bottom: -100px;
  right: -10%;
  background-image: url(/bgbottom.png);
  background-position: left;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    right: -25%;
    bottom: -100px;
  }
`;

export const Image = styled.img`
  width: 250px;
`;

export const IconImage = styled.img`
  width: 80px;
`;

export const Icon = styled.img`
  width: 20px;
  margin-right: 15px;
`;

export const DefaultImage = styled.img`
  width: 250px;
`;

export const ImageTitle = styled.div`
  position: relative;
`;

export const PhotoImage = styled.img`
  width: 250px;
  border-radius: 5px;
`;

export const PhotoImageRound = styled.img`
  width: 150px;
  border-radius: 50%;
  margin-top: 15px;
`;
