import styled from 'styled-components';

export const Tabs = styled.div`
  color: ${props => props.theme.third};
  display: flex;
  flex-flow: row;
  margin: 50px 0 30px 0;
  div {
    margin-right: 30px;
    padding: 10px;
    &:hover {
      color: ${props => props.theme.primary};
      cursor: pointer;
    }
  }
`;

export const Tab = styled.div`
  border-bottom: ${props => (props.active ? '3px solid #446a65' : 'none')};
`;

export const TabContent = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`;
