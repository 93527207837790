import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  BannerContainer,
  Banner,
  SectionContainer,
  NewsContainer,
  Title,
  SubTitle,
  Spinner,
} from "../../UI";
import NewsBanner from "../../../imgs/banners/news.jpg";
import { LanguageContext } from "../../../App";
import NewsCard from "./NewsCard";

const News = () => {
  const { t } = useTranslation();
  const { lg } = useContext(LanguageContext);

  const GET_NEWS = gql`
  {
    newsPosts(sort:"annoucement_date:desc") {
      data {
        id
        attributes {
          annoucement_date
          title_${lg}
          top     
          attachment_${lg}
        }
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_NEWS);
  // console.log(data);

  if (error) {
    return `Error! ${error.message}`;
  }

  const topNewsInfo =
    data &&
    data.newsPosts.data
      .filter((post) => post.attributes.top === true)
      .map((post) =>
        NewsCard({ post: { ...post.attributes, ...{ id: post.id } }, lg, t })
      );

  const latestNewsInfo =
    data &&
    data.newsPosts.data
      .filter((post) => !post.attributes.top)
      .slice(0, 5)
      .map((post) =>
        NewsCard({ post: { ...post.attributes, ...{ id: post.id } }, lg, t })
      );

  const previousNewsInfo =
    data &&
    data.newsPosts.data
      .filter((post) => !post.attributes.top)
      .slice(5, data.newsPosts.data.length)
      .map((post) =>
        NewsCard({ post: { ...post.attributes, ...{ id: post.id } }, lg, t })
      );

  return (
    <>
      <BannerContainer>
        <Banner src={NewsBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t("titles.announcement.news")}</Title>
      </SectionContainer>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <NewsContainer style={{ border: "none" }}>
            {topNewsInfo}
          </NewsContainer>
          <NewsContainer style={{ border: "none" }}>
            <SubTitle>{t("titles.announcement.news.latest_news")}</SubTitle>
            {latestNewsInfo}
          </NewsContainer>
          <NewsContainer style={{ border: "none" }}>
            <SubTitle>{t("titles.announcement.news.previous_news")}</SubTitle>
            {previousNewsInfo}
          </NewsContainer>
        </div>
      )}
    </>
  );
};

export default News;
