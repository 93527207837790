import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Events from '../Events';
import News from '../News';
import HomeBanner from '../../imgs/banners/home.jpg';
import {
  Image,
  IconImage,
  SectionContainer,
  HighlightWrap,
  FullWidthBackground,
  Button,
  Title,
  Text,
  Label,
  BannerContainer,
  Banner,
  Icon,
  TextOnImage,
  GridSideBySide,
  ImageTitle,
} from '../UI';
import corridor from '../../imgs/home/corridor.jpg';
import language from '../../imgs/home/language.png';
import brain from '../../imgs/home/cognition.png';
import infoIcon from '../../imgs/home/information.png';
import WebsiteIcon from '../../imgs/icons/website.png';
import InternalLink from '../Links/InternalLink';

const MobileHeader = styled.div`
  position: relative; 
  text-align: center;
  @media screen and (min-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;  

const MoreButton = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  padding: 10px 15px;
  border: 3px solid ${props => props.theme.third};
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const TextOnImageAbout = styled(TextOnImage)`
  width: 150px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 75px);
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

const LIC = styled.div`
 position: relative;
 margin-top: 50px;
  &::before{
   ${FullWidthBackground}
    top: -50px;
    height: 115%;
    transform: skewY(-5deg);
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    &::before{
      top: -20px;
      height: 105%;
    }
  }
`;

const LICContent = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  img {
    margin: 0 auto;
    display: block;
    height: 80px;
  }
  p {
    margin-right: 20px;
    margin-top: 30px;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    flex-direction: column;
    div {
      margin-top: 20px;
    }
  }
`;

const LICDescription = styled.div`
  display: flex;
  flex-flow: row;
  margin-top: 30px;
  align-items: center;
  p {
    width: 80%;
    margin-right: 20px;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    flex-flow: column;
    p {
      width: 100%;
      padding-left: 15px;
    }
  }
`;

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <BannerContainer>
        <Banner src={HomeBanner} />
      </BannerContainer>
      <SectionContainer>
        {/* <SectionContainer>
          <Title>
            <HighlightWrap>{t('home:highlight_annoucement')}</HighlightWrap>
            {t('cldc:cldc.titles.cldc')}
          </Title>
          <Text>
            <Label>{t('cldc:cldc.titles.website')}</Label>
            <a
              style={{ paddingLeft: 0 }}
              href={t('cldc:cldc.cldc.external_link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={WebsiteIcon} alt={t('cldc:cldc.cldc.external_link')}/>
            </a>
          </Text>
          <Text>
            <Label>{t('cldc:cldc.titles.date')}</Label>
            {t('cldc:cldc.cldc.date')}
          </Text>
          <Text>
            <Label>{t('cldc:cldc.titles.venue')}</Label>
            {t('cldc:cldc.cldc.venue')}
          </Text>
          <Text>
            <Label>{t('cldc:cldc.titles.call_deadline')}</Label>
            {t('cldc:cldc.cldc.call_deadline')}
          </Text>
          <Text>
            <Label>{t('cldc:cldc.titles.meeting_email')}</Label>
            {t('cldc:cldc.cldc.meeting_email')}
          </Text>
          <Text>
            <Label>{t('cldc:cldc.titles.program_book')}</Label>
          </Text>
          <MoreButton>
            <InternalLink
              style={{ paddingLeft: 0 }}
              link="/resources/cldc"
              linkText={t('cldc:cldc.titles.read_more')}
            />
          </MoreButton>
        </SectionContainer> */}
        <MobileHeader>
          <Title>{t('home:intro_title')}</Title>
        </MobileHeader>
        <GridSideBySide style={{ paddingLeft: '20px' }}>
          <Text>
            {t('home:intro').split('\n').map(line => <p>{line}</p>)}
          </Text>
          <ImageTitle>
            <TextOnImageAbout>
              <Title>
                {t('home:intro_title')}
              </Title>
            </TextOnImageAbout>
            <Image src={corridor} />
          </ImageTitle>
        </GridSideBySide>
      </SectionContainer>
      <SectionContainer>
        <LIC>
          <Title style={{ paddingTop: 45 }}>
            {t('home:LIC_title')}
          </Title>
          <LICContent>
            <div>
              <IconImage src={brain} />
              <Text>
                {t('home:LIC_intro_1')}
              </Text>
            </div>
            <div>
              <IconImage src={language} />
              <Text>
                {t('home:LIC_intro_2')}
              </Text>
            </div>
            <div>
              <IconImage src={infoIcon} />
              <Text>
                {t('home:LIC_intro_3')}
              </Text>
            </div>
          </LICContent>
          <LICDescription>
            <Text>
              {t('home:LIC_description')}
            </Text>
            <Button>
              <InternalLink
                style={{ paddingLeft: 0 }}
                link="/programs/lic"
                linkText={t('home:read_more')}
              />
            </Button>
          </LICDescription>
        </LIC>
      </SectionContainer>
      <Events />
      <News />
    </>
  );
};

export default Home;
