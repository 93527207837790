import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Subheader,
  Seperator,
} from '../UI';

const HeaderLink = () => {
  const { t } = useTranslation();
  return (
    <Header>
      <Link to="/" style={{paddingLeft:0}}>
        {t('graduate_institute_of_linguistics')}
        <Seperator>|</Seperator>
        <Subheader>{t('national_taiwan_university')}</Subheader>
      </Link>
    </Header>
  );
};

export default HeaderLink;
