import styled from 'styled-components';

export const MileStone = styled.div`
  display: flex;
  flex: row nowrap;
  margin-bottom: 40px;
  position: relative;
`;

export const MileStoneLine = styled.div`
  position: absolute;
  border: 1px dashed ${props => props.theme.third};
  left: 35px;
  height: 150px;
  z-index: -1;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    left: 10px;
  }
`;

export const MileStoneCircle = styled.div`
  width: 70px;
  height: 70px;
  background-color: ${props => props.theme.secondary};
  text-align: center;
  border-radius: 50%;
  border: 5px solid ${props => props.theme.third};
  margin-right: 50px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border: 10px solid ${props => props.theme.third};
    p {
      display: none;
    } 
  }
`;

export const MileStoneCard = styled.div`
  border: 1px solid lightgray;
  padding: 15px;
  padding-top: 0;
  border-radius: 5px;
  background-color: #fff;
  width: 550px;
`;
