import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  SubTitle,
  Label,
  Text,
  GridSideBySide,
  PhotoImage,
} from '../../UI';
import FacilitiesBanner from '../../../imgs/banners/facilities.jpg';

const Facilities = () => {
  const { t } = useTranslation();
  const facilitiesInfo = t('facilities:facilities.list', { returnObjects: true });

  const getFacilitiesInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <GridSideBySide justifyContent="flex-start">
        <div>
          <PhotoImage src={require(`../../../imgs/resources/facilities/${el.photo_name}`)} width={250} />
        </div>
        <div>
          <SubTitle>{el.name}</SubTitle>
          <Text>{el.description}</Text>
        </div>
      </GridSideBySide>
    )) : null;
    return info;
  };

  const facilities = getFacilitiesInfo(facilitiesInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={FacilitiesBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('facilities:facilities.titles.facilities')}</Title>
        <Text>{t('facilities:facilities.description')}</Text>
      </SectionContainer>
      <SectionContainer>
        {facilities}
        <Text>
          (
          <Label>{t('facilities:facilities.titles.copyright')}</Label>
          {t('facilities:facilities.copyright')}
          )
        </Text>
      </SectionContainer>
    </>
  );
};

export default Facilities;
