import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ActivitiesBanner from '../../../imgs/banners/activities.jpg';
import {
  Banner,
  BannerContainer,
  GridSideBySide,
  Image,
  SectionContainer,
  SubTitle,
  Text,
  Title,
  Spinner,
} from '../../UI';
import { LanguageContext } from '../../../App';
import baseURL from '../../../config/url';

const Activities = () => {
  const { t } = useTranslation();
  const { lg } = useContext(LanguageContext);

  const GET_ACTIVITIES = gql`
  {
    activities {
      data {
        attributes {
        title_${lg}
        description_${lg}
        photo {
          data {
            id
            attributes {
              url
            }
          }
        }

        }
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_ACTIVITIES);

  if (error) { return `Error! ${error.message}`; }

  const getInfo = (data) => {
    // console.log(data)

    const info = Array.isArray(data) ? data.map((el, idx) => (
      <GridSideBySide>
        <div className={`inverse${idx % 2}`}>
          <Image
            src={el.attributes.photo.data ? `${baseURL}${el.attributes.photo.data.attributes.url}` : null}
            alt="activity_photo"
            style={{ width: 350 }}
          />
        </div>
        <div>
          <SubTitle>{el.attributes[`title_${lg}`]}</SubTitle>
          <Text>
            <ReactMarkdown
              source={el.attributes[`description_${lg}`]}
              renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
            />
          </Text>
        </div>
      </GridSideBySide>
    )) : null;
    return info;
  };

  return (
    <>
      <BannerContainer>
        <Banner src={ActivitiesBanner} />
      </BannerContainer>
      {loading ? <Spinner /> : (
        <SectionContainer>
          <Title>{t('titles.about.activities.activities')}</Title>
          {getInfo(data.activities.data)}
        </SectionContainer>
      )}
    </>
  );
};

export default Activities;
