import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  Label,
  Card,
  CardTitle,
  PhotoImage,
  Text,
} from '../../UI';
import ROGILBanner from '../../../imgs/banners/rogil.jpg';
import ExternalLink from '../../Links/ExternalLink';

const ROGIL = () => {
  const { t } = useTranslation();
  const rogilInfo = t('rogil:rogil.rogil.list', { returnObjects: true });
  const getInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <Card>
        <div>
          <PhotoImage src={require(`../../../imgs/resources/rogil/${el.photo_name}`)} width={250} />
          <Text>
            <Label>
              (
              {t('rogil:rogil.titles.copyright')}
            </Label>
            {el.copyright}
              )
          </Text>
        </div>
        <div>
          <CardTitle>
            <ExternalLink
              noPadding
              link={el.external_link}
              linkText={(el.title)}
            />
          </CardTitle>
          <Text>{el.description}</Text>
        </div>
      </Card>
    )) : null;
    return info;
  };

  const rogil = getInfo(rogilInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={ROGILBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('rogil:rogil.titles.rogil')}</Title>
        {rogil}
      </SectionContainer>
    </>
  );
};

export default ROGIL;
