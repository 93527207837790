import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from "react-i18next";
import { gql } from '@apollo/client';
import FacultyBanner from '../../../imgs/banners/faculty.jpg';
import People from '../../Share/People';
import {
  Title,
  BannerContainer,
  Banner,
  Spinner,
} from '../../UI';
import { LanguageContext } from '../../../App';
import { typeIncompatibleSpreadMessage } from 'graphql/validation/rules/PossibleFragmentSpreads';

const Faculty = () => {
  const { lg } = useContext(LanguageContext);
  const { t } = useTranslation();

  const GET_FACULTIES = gql`
  query Faculties {
    faculties(pagination: {limit: 100}, sort: "sort_order:asc") {
      data {
        attributes {
        photo {
          data {
          id
          attributes {
            url
          }
        }
      }
      name_zh_tw
      name_en
      title_${lg}
      email
      phone
      fax
      website
      cv
      education_${lg}
      research_interests_${lg}
      status
        }
      }
    }
  }
  `;
  const { loading, error, data } = useQuery(GET_FACULTIES);

  if (error) { return `Error! ${error.message}`; }

  let current
  let joint
  let adjunct
  let retired

  if (loading) {
    return <Spinner />;
  } else {
    current = data.faculties.data.filter((faculty) => faculty.attributes.status === 'CURRENT');
    joint = data.faculties.data.filter((faculty) => faculty.attributes.status === 'JOINT');
    adjunct = data.faculties.data.filter((faculty) => faculty.attributes.status === 'ADJUNCT');
    retired = data.faculties.data.filter((faculty) => faculty.attributes.status === 'RETIRED');
  }

  return (
    <>
      <BannerContainer>
        <Banner src={FacultyBanner} />
      </BannerContainer>
    <Title>{t("titles.people.faculty.faculty")}</Title>
      {loading ? <Spinner /> : (
        <People
          title="titles.people.faculty.full_time"
          data={current}
        />
      )}
      {loading ? <Spinner /> : (
        (joint.length > 0) &&
        <People
          title="titles.people.faculty.joint"
          data={joint}
        />
      )}
      {loading ? <Spinner /> : (
        (adjunct.length > 0) &&
        <People
          title="titles.people.faculty.adjunct"
          data={adjunct}
        />
      )}
      {loading ? <Spinner /> : (
        (retired.length > 0) &&
        <People
          title="titles.people.faculty.retired"
          data={retired}
        />
      )}
    </>
  );
};

export default Faculty;
