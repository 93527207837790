import styled, { css } from 'styled-components';

export const PageContainer = styled.div`
  position: relative;
  max-width: ${props => props.theme.maxWidth};
  min-height: 400px;
  padding: 0px ${props => props.theme.pagePadding}; 
  margin: 0 auto;
  margin-top: 140px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    padding: 0 ${props => props.theme.mobilePagePadding};
    margin-top: 105px;
    overflow-x: hidden;
  }
`;

export const SectionContainer = styled.div`
  margin: ${props => props.theme.sectionMargin} 0;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    margin-top: 0;
  }
`;

export const FullWidth = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -1;
  margin-left: calc((100vw - ${props => props.theme.maxWidth}) / -2 - ${props => props.theme.pagePadding} );
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    margin-left: -${props => props.theme.mobilePagePadding};
  }
`;

export const FullWidthBackground = css`
  ${FullWidth}
  content: "";
  background-color: ${props => props.theme.secondary};
`;

export const FooterContainer = styled.div`
  position: relative;
  width: 100vw;
  background-color: ${props => props.theme.third};
  margin-top: ${props => props.theme.sectionMargin};
  padding: 30px;
  text-align: center;
  p {
    color: ${props => props.theme.secondary};
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    margin-top: 0px;
  }
`;

export const TextWithIconContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  p {
    margin: 0;
    padding: 10px 0;
  }
`;

export const ContentContainer = styled.div`
  margin-bottom: 20px;
  border: 1px solid lightgray;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: #fff;
`;

export const ListContainer = styled.div`
  border: 1px solid ${props => props.theme.lightgray};
  padding: 30px 45px;
  width: 600px;
  margin-bottom: 20px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    width: 90vw;
    padding: 30px 15px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    justify-content: center;
  }
`;

export const RowWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const EventsSummaryWrap = styled.div`
  display: flex;
  flex-flow: row nowrap; 
  align-items: center;
  padding: 10px 0 0px 0;
`;

export const HighlightWrap = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  background-color: rgb(24, 38, 36);
  padding: 5px 10px;
  border-radius: 3px;
  margin-right: 15px;
  color: rgb(227, 233, 232);
`;

export const Wrapper = styled.div`
  padding-bottom: 15px;
  ${props => props.marginTop && `
    margin-top: 25px;
  `}
`;

export const NewsContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.lightgray};
  padding: 30px 45px;
  width: 600px;
  margin: 0 auto;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    width: 90vw;
    padding: 30px 15px;
  }
`;
