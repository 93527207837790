import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
  TextTitle,
  Spacing,
  RowWrap,
  Time,
  Icon,
  NewsContainer,
} from '../../UI';
import pinIcon from '../../../imgs/icons/pin.png';
import getDayOfWeek from '../../../utility';
import baseURL from '../../../config/url';

const NewsCard = ({ post, lg, t }) => {
  const annoucementDate = `${post.annoucement_date} (${getDayOfWeek({ t, day: post.annoucement_date })})`;

  return (
    <NewsContainer key={post.id}>
      {post.top ? <Icon src={pinIcon} /> : null}
      <Time>
        {t('titles.announcement.announcement_date')}
        :
        <Spacing>{annoucementDate}</Spacing>
      </Time>
      <TextTitle>
        <ReactMarkdown
          source={post[`title_${lg}`]}
          renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
        />
      </TextTitle>
      <RowWrap>
        {post[`attachment_${lg}`] ? (
          <ReactMarkdown
            source={post[`attachment_${lg}`]}
            renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
          />
        ) : null}
      </RowWrap>
    </NewsContainer>
  );
};

NewsCard.propTypes = {
  post: PropTypes.objectOf(PropTypes.string).isRequired,
  lg: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default NewsCard;
