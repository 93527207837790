import React from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Home from '../components/Home';
import Faculty from '../components/People/Faculty';
import PostDoc from '../components/People/PostDoc';
import Staff from '../components/People/Staff';
import Students from '../components/People/Students';
import Alumni from '../components/People/Alumni';
import MAPrograms from '../components/Programs/MA';
import PhDPrograms from '../components/Programs/PhD';
import LICPrograms from '../components/Programs/LIC';
import Funding from '../components/Programs/Funding';
import InternationalStudents from '../components/Programs/InternationalStudents';
import CurrentCourses from '../components/Courses/CurrentCourses';
import DegreeRequirements from '../components/Courses/DegreeRequirements';
import Research from '../components/Research';
import OnlineCourses from '../components/Resources/OnlineCourses';
import CLDC from '../components/Resources/CLDC';
import Corpora from '../components/Resources/Corpora';
import NTUE from '../components/Resources/NTUE';
import TOL from '../components/Resources/TOL';
import Facilities from '../components/Resources/Facilities';
import ROGIL from '../components/Resources/ROGIL';
import Events from '../components/Announcement/Events';
import News from '../components/Announcement/News';
import Newsletters from '../components/Announcement/Newsletters';
import Location from '../components/About/Location';
import ContactInfo from '../components/About/ContactInfo';
import JobOpenings from '../components/About/JobOpenings';
import Activities from '../components/About/Activities';
import PageNotFound from '../components/PageNotFound';

const routes = [
  {
    path: '/uploads/',
    component: PageNotFound,
  },
  {
    path: '/static/',
    component: PageNotFound,
  },
  {
    path: '/people/faculty',
    component: Faculty,
  },
  {
    path: '/people/post-doc',
    component: PostDoc,
  },
  {
    path: '/people/staff',
    component: Staff,
  },
  {
    path: '/people/students',
    component: Students,
  },
  {
    path: '/people/alumni',
    component: Alumni,
  },
  {
    path: '/programs/ma-programs',
    component: MAPrograms,
  },
  {
    path: '/programs/phd-programs',
    component: PhDPrograms,
  },
  {
    path: '/programs/lic',
    component: LICPrograms,
  },
  {
    path: '/programs/funding',
    component: Funding,
  },
  {
    path: '/programs/international-students',
    component: InternationalStudents,
  },
  {
    path: '/courses/degree-requirements',
    component: DegreeRequirements,
  },
  {
    path: '/courses/current-courses',
    component: CurrentCourses,
  },
  {
    path: '/research',
    component: Research,
  },
  {
    path: '/announcement/news',
    component: News,
  },
  {
    path: '/announcement/events',
    component: Events,
  },
  {
    path: '/announcement/previous-newsletters',
    component: Newsletters,
  },
  {
    path: '/resources/online-courses',
    component: OnlineCourses,
  },
  {
    path: '/resources/cldc',
    component: CLDC,
  },
  {
    path: '/resources/corpora',
    component: Corpora,
  },
  {
    path: '/resources/ntue',
    component: NTUE,
  },
  {
    path: '/resources/tol',
    component: TOL,
  },
  {
    path: '/resources/facilities',
    component: Facilities,
  },
  {
    path: '/resources/rogil',
    component: ROGIL,
  },
  {
    path: '/about/location',
    component: Location,
  },
  {
    path: '/about/contact-info',
    component: ContactInfo,
  },
  {
    path: '/about/job-openings',
    component: JobOpenings,
  },
  {
    path: '/about/activities',
    component: Activities,
  },
  {
    path: '/uploads',
    component: PageNotFound,
  },
  {
    path: '/static',
    component: PageNotFound,
  },
  {
    path: '/404',
    component: PageNotFound,
  },
  {
    path: '/',
    component: Home,
  },
];

const AppRoutes = () => (
  <Switch>
    {routes.map(({ path, component: Component }) => (
      <Route
        exact
        key={path}
        path={path}
        render={routerProps => <Component {...routerProps} />}
      />
    ))}
    <Redirect to="/404" component={PageNotFound} />
  </Switch>
);

export default AppRoutes;
