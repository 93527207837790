import styled from 'styled-components';

export const Header = styled.h1`
  font-size: 3.2rem;
  font-weight: 400;
  color: ${props => props.theme.primary};
  a {
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    font-size: 2rem;
    margin: 0;
    max-width: 250px;
  }
`;

export const Subheader = styled.span`
  font-size: 2.4rem;
  color: ${props => props.theme.primary};
  a {
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    display: block;
    font-size: 1.6rem;
    padding-top: 5px;
  }
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  color: ${props => props.theme.primary};
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    line-height: 3.6rem;
  }
`;

export const SubTitle = styled.h3`
  font-size: 2rem;
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
`;

export const TextTitle = styled.p`
  font-size: 1.8rem;
  padding-bottom: 10px;
`;

export const Label = styled.span`
  &::after{
    content: ':';
    padding-right: 10px;
  }
`;

export const ContextualTitle = styled.p`
  font-weight: bold;
  padding-left: 0.8rem;
  border-left: 3px solid ${props => props.theme.third};
`;

export const Text = styled.p`
  font-size: 1.6rem;
  color: ${props => props.theme.primary};
`;

export const Seperator = styled.span`
  padding: 0 5px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

export const TextOnImage = styled.div`
  position: absolute;
  width: 250px;
  height: 100px;
  padding: 30px;
  top: calc(50% - 50px);
  left: calc(50% - 125px);
  text-align: center;
  background-color: rgba(68,106,101,.6);
  h2 {
    color: ${props => props.theme.secondary};
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    top: calc(50% - 50px);
    left: calc(50vw - 125px);
  }
`;

export const Time = styled.span`
  color: ${props => props.theme.darkgray};
  margin: 0;
`;

export const Spacing = styled.span`
  margin-left: 10px;
`;
