import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Wrapper,
  Title,
  SubTitle,
  ContextualTitle,
  Text,
  HorizontalLine,
  MileStone,
  MileStoneCircle,
  MileStoneLine,
  MileStoneCard,
} from '../../UI';
import PhDProgramsBanner from '../../../imgs/banners/phd_programs.jpg';
import ExternalLink from '../../Links/ExternalLink';
import InternalLink from '../../Links/InternalLink';
import PDFLink from '../../Links/PDFLink';

const PhDPrograms = () => {
  const { t } = useTranslation();

  const getSource = (fileName) => {
    return require('../../../pdf/programs/phd/' + fileName);
  };

  const EntranceExamDocuments = t('phd_programs:phd_programs.entrance_exam.required_documents', { returnObjects: true });

  const noticesInfo = t('phd_programs:phd_programs.notices', { returnObjects: true });

  const getNotices = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <p>
        {el.pdf_link ? (
          <Text>
            {el.description}
            <PDFLink link={getSource(el.pdf_link)} />
          </Text>
        ) : null}
        {el.external_link ? (
          <Text>
            {el.description}
            <ExternalLink link={el.external_link} />
          </Text>
        ) : null}
        {el.internal_link ? (
          <Text>
            {el.description}
            <InternalLink link={el.internal_link} />
          </Text>
        ) : null}
        {el.no_link ? null : null}
      </p>
    )) : null;
    return info;
  };

  const notices = getNotices(noticesInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={PhDProgramsBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('phd_programs:phd_programs.titles.phd_programs')}</Title>
        <Text>{t('phd_programs:phd_programs.description')}</Text>
      </SectionContainer>
      <SectionContainer style={{ marginBottom: 80 }}>
        <SubTitle>
          {t('phd_programs:phd_programs.titles.how_to_apply')}
        </SubTitle>
        <HorizontalLine style={{ marginBottom: 50 }} />

        <MileStone>
          <MileStoneCircle>
            <p>{t('phd_programs:phd_programs.titles.step_1')}</p>
          </MileStoneCircle>
          <MileStoneLine />
          <MileStoneCard>
            <p>{t('phd_programs:phd_programs.how_to_apply.step_1.title')}</p>
            <p>
              {t('phd_programs:phd_programs.how_to_apply.step_1.description')}
              <ExternalLink
                link={t('phd_programs:phd_programs.how_to_apply.step_1.external_link')}
              />
            </p>
          </MileStoneCard>
        </MileStone>

        <MileStone>
          <MileStoneCircle>
            <p>{t('phd_programs:phd_programs.titles.step_2')}</p>
          </MileStoneCircle>
          <MileStoneLine />
          <MileStoneCard>
            <p>{t('phd_programs:phd_programs.how_to_apply.step_2.title')}</p>
            <p>
              {t('phd_programs:phd_programs.how_to_apply.step_2.description')}
              <PDFLink
                link={getSource(t('phd_programs:phd_programs.how_to_apply.step_2.pdf_link'), )}
              />
            </p>
          </MileStoneCard>
        </MileStone>

        <MileStone>
          <MileStoneCircle>
            <p>{t('phd_programs:phd_programs.titles.step_3')}</p>
          </MileStoneCircle>
          <MileStoneLine />
          <MileStoneCard>
            <p>{t('phd_programs:phd_programs.how_to_apply.step_3.title')}</p>
          </MileStoneCard>
        </MileStone>

        <MileStone>
          <MileStoneCircle>
            <p>{t('phd_programs:phd_programs.titles.step_4')}</p>
          </MileStoneCircle>
          <MileStoneCard>
            <p>{t('phd_programs:phd_programs.how_to_apply.step_4.title')}</p>
          </MileStoneCard>
        </MileStone>
      </SectionContainer>

      <SectionContainer>
        <SubTitle>{t('phd_programs:phd_programs.titles.entrance_exam')}</SubTitle>
        <HorizontalLine />

        <Wrapper marginTop>
          <ContextualTitle>
            {t('phd_programs:phd_programs.titles.number_of_admission_offers')}
          </ContextualTitle>
          <p>{t('phd_programs:phd_programs.entrance_exam.number_of_admission_offers')}</p>
        </Wrapper>

        <Wrapper>
          <ContextualTitle>
            {t('phd_programs:phd_programs.titles.required_documents')}
          </ContextualTitle>
          {EntranceExamDocuments.map(el => <Text>{el}</Text>)}
        </Wrapper>

        <Wrapper>
          <ContextualTitle>
            {t('phd_programs:phd_programs.titles.selection_criteria_for_interviews')}
          </ContextualTitle>
          <p>{t('phd_programs:phd_programs.entrance_exam.selection_criteria_for_interviews')}</p>
        </Wrapper>
      </SectionContainer>

      <SectionContainer>
        <SubTitle>{t('phd_programs:phd_programs.titles.transdoc')}</SubTitle>
        <HorizontalLine />

        <Wrapper marginTop>
          {t('phd_programs:phd_programs.transdoc.description')}
          <ExternalLink
            link={t('phd_programs:phd_programs.transdoc.external_link')}
          />
        </Wrapper>
      </SectionContainer>

      <SectionContainer>
        <SubTitle>{t('phd_programs:phd_programs.titles.notices')}</SubTitle>
        <HorizontalLine />

        <Wrapper marginTop>
          {notices}
        </Wrapper>
      </SectionContainer>

      <SectionContainer>
        <SubTitle>{t('phd_programs:phd_programs.titles.regulations')}</SubTitle>
        <HorizontalLine />

        <Wrapper marginTop>
          {t('phd_programs:phd_programs.regulations.description')}
          <InternalLink link={t('phd_programs:phd_programs.regulations.internal_link')} />
        </Wrapper>
      </SectionContainer>
    </>
  );
};

export default PhDPrograms;
