import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Icon,
  TextWithIconContainer,
} from '../UI';

const CardContent = ({
  keyName,
  fieldsWithIcon,
  value,
  children,
}) => {
  const checkIfContainField = ({ key, fields }) => {
    const fieldsCheck = Object.keys(fields).map(field => (key.includes(field) ? field : null));
    return fields[fieldsCheck.filter(field => field !== null)];
  };

  const renderValue = () => (Array.isArray(value) ? value.join(' / ') : value);

  return (
    <TextWithIconContainer key={keyName}>
      <Icon src={checkIfContainField({ key: keyName, fields: fieldsWithIcon })} />
      <Text>
        {children || renderValue()}
      </Text>
    </TextWithIconContainer>
  );
};

CardContent.propTypes = {
  keyName: PropTypes.string.isRequired,
  fieldsWithIcon: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.element,
};

CardContent.defaultProps = {
  children: null,
};

export default CardContent;
