import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
} from '../UI';

const PageNotFound = () => (
  <div style={{
    marginTop: 150,
    paddingTop: 30,
    textAlign: 'center',
    height: '50vh',
  }}
  >
    <p>Page Not Found</p>
    <Link to="/">
      <Button>Go to Home page</Button>
    </Link>
  </div>
);


export default PageNotFound;
