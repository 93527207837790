import styled from 'styled-components';

export const Workshop = styled.div`
  display: flex;
  flex: row nowrap;
  margin-bottom: 40px;
  position: relative;
`;

export const WorkshopBlock = styled.div`
  width: 150px;
  background-color: ${props => props.theme.secondary};
  text-align: center;
  margin-right: 30px;
  border-radius: 5px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    margin-right: 10px;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const WorkshopBreak = styled.div`
  width: 800px;
  background-color: ${props => props.theme.secondary};
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    width: 70vw;
    min-height: 115px;
  }
`;

export const WorkshopCard = styled.div`
  border: 1px solid lightgray;
  box-shadow: 6px 6px 1px rgba(20%,20%,40%,0.5);
  padding: 25px;
  padding-top: 0;
  border-radius: 5px;
  background-color: #fff;
  width: 800px;
  height: 100%;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    width: 70vw;
  }
`;

export const WorkshopSpeaker = styled.div`
  background-color: #fff;  
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  div {
    margin-right: 40px;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    flex-flow: column;
    div {
      margin: 0;
    }
`;

export const WorkshopAbstract = styled.div`
  position: relative;
  padding: 25px;
  padding-top: 0;
  background-color: #e3e9e8;
  opacity: 0.7;
  border-radius: 5px;
  text-align: justify;
  width: 100%;
  max-height: 250px;
  overflow: scroll;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    max-height: 500px;
    text-align: left;
    margin-left: 0;
    padding: 15px;
  }
`;
