import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import PostDocBanner from '../../../imgs/banners/post_doc.jpg';
import People from '../../Share/People';
import {
  BannerContainer,
  Banner,
  Spinner,
} from '../../UI';
import { LanguageContext } from '../../../App';

const PostDoc = () => {
  const { lg } = useContext(LanguageContext);
  const GET_POSTDOC = gql`
  {
    postdocs {
      data {
        attributes {
          photo{
            data {
              id
              attributes {
                url
              }
            }
          }
          name_zh_tw
          name_en
          title_${lg}
          email
          phone
          fax
          website
          education_${lg}
          research_interests_${lg}
        }
      }
    }
  }
  `;
  const { loading, error, data } = useQuery(GET_POSTDOC);

  if (error) { return `Error! ${error.message}`; }

  return (
    <>
      <BannerContainer>
        <Banner src={PostDocBanner} />
      </BannerContainer>
      {loading ? <Spinner /> : (
        <People
          title="titles.people.postdoc.postdoc"
          data={data.postdocs.data}
        />
      )}
    </>
  );
};

export default PostDoc;
