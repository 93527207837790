import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../UI';
import linkIcon from '../../imgs/icons/pdf_link.png';

const PDFLink = ({ link, linkText, noPadding }) => (
  <a
    href={link}
    target="_blank"
    style={noPadding ? ({paddingLeft: 0}):({})}
  >
    {linkText || <Icon src={linkIcon} />}
  </a>
);

PDFLink.propTypes = {
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default PDFLink;
