import React, {
  useState,
  useContext,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import StudentsBanner from '../../../imgs/banners/students.jpg';
import {
  Tabs,
  Tab,
  TabContent,
  BannerContainer,
  Banner,
  Title,
  SectionContainer,
  Spinner,
} from '../../UI';
import People from '../../Share/People';
import { LanguageContext } from '../../../App';

const getStudents = ({ degree }) => {
  const { lg } = useContext(LanguageContext);
  const GET_STUDENTS = gql`
    query($where: StudentFiltersInput){
      students(filters: $where){
        data {
          id
          attributes {
          name_zh_tw
          name_en
          degree
          website
          research_interests_${lg}
          photo {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
    `;

  const { loading, error, data } = useQuery(
    GET_STUDENTS, { variables: { "where": { "degree": {"contains": degree } } } },
  );

  if (loading) { return <Spinner />; }
  if (error) { return `Error! ${error.message}`; }

  return (
    <People
      resource="students"
      data={data.students.data}
    />
  );
};

const Students = () => {
  const { t } = useTranslation();
  const [activeTabContent, setActiveTabContent] = useState('PHD');
  const handleTabOnClick = value => setActiveTabContent(value);
  const PhDStudents = () => getStudents({ degree: 'PHD' });
  const MAStudents = () => getStudents({ degree: 'MA' });

  return (
    <>
      <BannerContainer>
        <Banner src={StudentsBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('titles.people.students.students')}</Title>
        <Tabs>
          <Tab
            active={activeTabContent === 'PHD'}
            onClick={() => handleTabOnClick('PHD')}
          >
            {t('titles.people.students.phd_students')}
          </Tab>
          <Tab
            active={activeTabContent === 'MA'}
            onClick={() => handleTabOnClick('MA')}
          >
            {t('titles.people.students.ma_students')}
          </Tab>
        </Tabs>
        <TabContent show={activeTabContent === 'PHD'}>
          <PhDStudents />
        </TabContent>
        <TabContent show={activeTabContent === 'MA'}>
          <MAStudents />
        </TabContent>
      </SectionContainer>
    </>
  );
};

export default Students;
