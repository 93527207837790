import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  SubTitle,
  Label,
  Card,
  CardTitle,
  PhotoImage,
  Text,
  GridSideBySide,
} from '../../UI';
import OnlineCoursesBanner from '../../../imgs/banners/online_courses.jpg';
import ExternalLink from '../../Links/ExternalLink';
import SuCourses from '../../../imgs/resources/online_courses/online_courses_Su_I-Wen.jpg';

const OnlineCourses = () => {
  const { t } = useTranslation();
  const coursesInfo = t('online_courses:online_courses.online_courses.list', { returnObjects: true });
  const speechesInfo = t('online_courses:online_courses.speeches.list', { returnObjects: true });
  const getCourseInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <div>
        <CardTitle>
          <SubTitle>{el.title}</SubTitle>
        </CardTitle>
        <Text>
          <Label>{t('online_courses:online_courses.titles.instructor')}</Label>
          {el.instructor}
        </Text>
        <Text>
          <Label>{t('online_courses:online_courses.titles.course_description')}</Label>
          {el.course_description}
        </Text>
        <Text>
          <ExternalLink
            noPadding
            link={el.external_link}
            linkText={t('online_courses:online_courses.titles.external_link')}
          />
        </Text>
      </div>
    )) : null;
    return info;
  };

  const getSpeechInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <SectionContainer>
        <GridSideBySide
          justifyContent="flex-start"
        >
          <PhotoImage src={require(`../../../imgs/resources/online_courses/${el.photo_name}`)} />
          <div style={{ marginTop: 0, marginLeft: 40 }}>
            <SubTitle style={{ marginTop: 0 }}>{el.title}</SubTitle>
            {el.external_links.map(link => (
              <div style={{ margin: '15px 0' }}>
                <ExternalLink
                  noPadding
                  link={link.external_link}
                  linkText={link.title}
                />
              </div>
            ))}
          </div>
        </GridSideBySide>
      </SectionContainer>
    )) : null;
    return info;
  };

  const courses = getCourseInfo(coursesInfo);
  const speeches = getSpeechInfo(speechesInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={OnlineCoursesBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('online_courses:online_courses.titles.online_courses')}</Title>
        <Card>
          <div>
            <PhotoImage src={SuCourses} />
          </div>
          {courses}
        </Card>
      </SectionContainer>
      <SectionContainer>
        <Title>{t('online_courses:online_courses.titles.speeches')}</Title>
        {speeches}
      </SectionContainer>
    </>
  );
};

export default OnlineCourses;
