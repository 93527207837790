import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  SubTitle,
  Text,
  HorizontalLine,
  Spinner,
} from '../../UI';
import JobOpeningsBanner from '../../../imgs/banners/job_openings.jpg';
import { LanguageContext } from '../../../App';
import baseURL from '../../../config/url';

const JobOpenings = () => {
  const { t } = useTranslation();
  const { lg } = useContext(LanguageContext);

  const GET_JOBOPENINGS = gql`
  {
    jobOpenings {
      data {
        attributes {
          title_${lg}
          position_${lg}
          description_${lg}
        }
      }
    }
  }
  `;
  const { loading, error, data } = useQuery(GET_JOBOPENINGS);

  if (error) { return `Error! ${error.message}`; }

  const getInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <div>
        <SubTitle>{el[`title_${lg}`]}</SubTitle>
        <Text>{el[`position_${lg}`]}</Text>
        <Text>
          <ReactMarkdown
            source={el[`description_${lg}`]}
            renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
          />
        </Text>
        <HorizontalLine />
      </div>
    )) : null;
    return info;
  };

  return (
    <>
      <BannerContainer>
        <Banner src={JobOpeningsBanner} />
      </BannerContainer>
      {loading ? <Spinner /> : (
        <SectionContainer>
          <Title>{t('titles.about.job_openings.job_openings')}</Title>
          {getInfo(data.jobOpenings)}
        </SectionContainer>
      )}
    </>
  );
};

export default JobOpenings;
