import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: flex-end;
  border: 1px solid green;
`;


export default StyledLink;
