import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  SubTitle,
  Text,
} from '../../UI';
import LocationBanner from '../../../imgs/banners/location.jpg';

const GILLocation = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerContainer>
        <Banner src={LocationBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('location:location.titles.location')}</Title>
        <SubTitle>{t('location:location.titles.address')}</SubTitle>
        <Text>{t('location:location.address')}</Text>
        <SubTitle>{t('location:location.titles.walking_directions')}</SubTitle>
        <Text>{t('location:location.walking_directions')}</Text>
        <div>
          <iframe
            title="google map"
            width="100%"
            height="250"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src={t('location:location.google_map_api')}
          />
        </div>
      </SectionContainer>
    </>
  );
};

export default GILLocation;
