import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LogoImage } from '../UI';
import logo from '../../imgs/gil_logo.png';

const LogoContainer = styled.div`
  margin: 10px 0;
  line-height: 0;
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    margin: 0;
  }
`;

const LogoLink = () => (
  <LogoContainer>
    <Link to="/" style={{ lineHeight: 0 }}>
      <LogoImage src={logo} />
    </Link>
  </LogoContainer>
);

export default LogoLink;
