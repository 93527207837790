import React, {
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import {
  BannerContainer,
  Banner,
  Title,
  SectionContainer,
  Tabs,
  Tab,
  TabContent,
  Spinner,
} from '../../UI';
import AlumniBanner from '../../../imgs/banners/alumni.jpg';
import People from '../../Share/People';
import { LanguageContext } from '../../../App';

const getAlumni = ({ degree, lg }) => {
  const GET_ALUMNI = gql`
    query($where: AlumnusFiltersInput){
      alumni(filters: $where) {
        data {
          attributes {
            name_zh_tw
            name_en
            degree
            graduate_year
            thesis_title_${lg}
            advisor_${lg}
          }
        }
      }
    }
    `;

  const { loading, error, data } = useQuery(
    GET_ALUMNI, { variables: { where: { degree: {contains: degree } } } },
  );

  if (loading) { return <Spinner />; }
  if (error) { return `Error! ${error.message}`; }

  // Sort Alumni by descending graduation year
  let alumniSorted = [...data.alumni.data]
  alumniSorted.sort(function (a, b) {
    return b.attributes.graduate_year.toString().localeCompare(a.attributes.graduate_year.toString())
  });

  return (
    <People
      resource="students"
      data={alumniSorted}
    />
  );
};

const Alumni = () => {
  const { t, i18n } = useTranslation();
  const { lg } = useContext(LanguageContext);

  const PhDAlumni = () => getAlumni({ degree: 'PHD', lg });
  const MAAlumni = () => getAlumni({ degree: 'MA', lg });
  const [activeTabContent, setActiveTabContent] = useState('PHD');
  const handleTabOnClick = value => setActiveTabContent(value);

  return (
    <>
      <BannerContainer>
        <Banner src={AlumniBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>
          {' '}
          {t('titles.people.alumni.alumni')}
        </Title>
        <Tabs>
          <Tab
            active={activeTabContent === 'PHD'}
            onClick={() => handleTabOnClick('PHD')}
          >
            {t('titles.people.alumni.phd')}
          </Tab>
          <Tab
            active={activeTabContent === 'MA'}
            onClick={() => handleTabOnClick('MA')}
          >
            {t('titles.people.alumni.ma')}
          </Tab>
        </Tabs>
        <TabContent show={activeTabContent === 'PHD'}>
          <PhDAlumni />
        </TabContent>
        <TabContent show={activeTabContent === 'MA'}>
          <MAAlumni />
        </TabContent>
      </SectionContainer>
    </>
  );
};

export default Alumni;
