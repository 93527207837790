import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../UI';
import linkIcon from '../../imgs/icons/external_link.png';


const ExternalLink = ({ link, linkText, noPadding }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    style={noPadding ? ({ paddingLeft: 0 }) : ({})}
  >
    {linkText || <Icon src={linkIcon} />}
  </a>
);


ExternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  noPadding: PropTypes.string.isRequired,
};

export default ExternalLink;
