import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  SubTitle,
  Spinner,
} from '../../UI';
import EventsBanner from '../../../imgs/banners/events.jpg';
import { LanguageContext } from '../../../App';
import EventCard from './EventCard';

const Events = () => {
  const { t } = useTranslation();
  const { lg } = useContext(LanguageContext);

  const GET_EVENTS = gql`
  {
    eventsPosts(sort:"start_date:desc") {
      data {
        id
        attributes {
          annoucement_date
          start_date
          end_date
          time
          title_${lg}
          summary_${lg}
          location_${lg}
          speaker_${lg}
          attachment_${lg}
        }
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_EVENTS);
  // console.log(data)

  if (error) { return `Error! ${error.message}`; }

  const upcomingEventsInfo = data && data.eventsPosts.data.slice(0, 5).map(
    post => EventCard({ post: {...post.attributes, ...{id: post.id}}, lg, t }),
  );

  const pastEventsInfo = data && data.eventsPosts.data.slice(5, data.eventsPosts.length).map(
    post => EventCard({ post: {...post.attributes, ...{id: post.id}}, lg, t }),
  );

  return (
    <>
      <BannerContainer>
        <Banner src={EventsBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('titles.announcement.events.events')}</Title>
      </SectionContainer>
      {loading ? <Spinner /> : (
        <div>
          <SectionContainer>
            <SubTitle>{t('titles.announcement.events.upcoming_events')}</SubTitle>
            {upcomingEventsInfo}
          </SectionContainer>
          <SectionContainer>
            <SubTitle>{t('titles.announcement.events.past_events')}</SubTitle>
            {pastEventsInfo}
          </SectionContainer>
        </div>
      )}
    </>
  );
};

export default Events;
