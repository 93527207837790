import React from 'react';
import NavMobile from './NavMobile';
import NavDesktop from './NavDesktop';

const Appbar = () => (
  <>
    <NavMobile />
    <NavDesktop />
  </>
);

export default Appbar;
