import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
  ContentContainer,
  TextTitle,
  Label,
  Spacing,
  Time,
  RowWrap,
} from '../../UI';
import getDayOfWeek from '../../../utility';
import baseURL from '../../../config/url';

const EventCard = ({ post, lg, t }) => {
  const annoucementDate = `${post.annoucement_date} (${getDayOfWeek({ t, day: post.annoucement_date })})`;
  const startDate = `${post.start_date} (${getDayOfWeek({ t, day: post.start_date })})`;
  const endDate = post.end_date ? `${post.end_date} (${getDayOfWeek({ t, day: post.end_date })})` : '';

  return (
    <ContentContainer key={post.id}>
      <Time>
        {t('titles.announcement.announcement_date')}
        :
        <Spacing>{annoucementDate}</Spacing>
      </Time>
      <TextTitle>{post[`title_${lg}`]}</TextTitle>
      <p>
        <Label>{t('titles.announcement.events.events_date')}</Label>
        {post.end_date ? `${startDate} - ${endDate}` : startDate}
      </p>
      <p>
        <Label>{t('titles.announcement.events.events_time')}</Label>
        {post.time}
      </p>
      <p>
        <Label>{t('titles.announcement.events.location')}</Label>
        {post[`location_${lg}`]}
      </p>
      <p>
        <Label>{t('titles.announcement.events.speaker')}</Label>
        {post[`speaker_${lg}`]}
      </p>
      <div>
        <Label>{t('titles.announcement.events.description')}</Label>
        <ReactMarkdown
          source={post[`summary_${lg}`]}
          renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
        />
      </div>
      <RowWrap>
        <Label>{t('titles.announcement.events.attachment')}</Label>
        <ReactMarkdown
          source={post[`attachment_${lg}`]}
          renderers={{ link: ({ href, children }) => <a href={href.includes('uploads') ? baseURL.concat(href) : href} target="_blank" rel="noreferrer noopener">{children}</a> }}
        />
      </RowWrap>
    </ContentContainer>
  );
};

EventCard.propTypes = {
  post: PropTypes.objectOf(PropTypes.string).isRequired,
  lg: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default EventCard;
