import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  SectionContainer,
  Banner,
  Title,
  SubTitle,
  Label,
  Card,
  CardTitle,
  Text,
  PhotoImage,
} from '../UI';
import ResearchBanner from '../../imgs/banners/research.jpg';
import ExternalLink from '../Links/ExternalLink';

const Research = () => {
  const { t } = useTranslation();
  const labsInfo = t('research:research.labs.list', { returnObjects: true });
  const getInfo = (data) => {
    const content = Array.isArray(data) ? data.map((el) => {
      const description = el.description.split('/n').map(line => <p>{line}</p>);
      return (
        <Card key={el.name}>
          <div>
            <PhotoImage src={require(`../../imgs/research/${el.photo_name}`)} width={250} />
            <Text>
              (
              <Label>{t('research:research.titles.copyright')}</Label>
              {el.copyright}
              )
            </Text>
          </div>
          <div>
            <CardTitle>
              <ExternalLink
                noPadding
                link={el.external_link}
                linkText={el.name}
              />
            </CardTitle>
            <Text>
              <Label>{t('research:research.titles.principal_investigator')}</Label>
              {el.principal_investigator}
            </Text>
            <Text>{description}</Text>
          </div>
        </Card>
      );
    }) : null;
    return content;
  };

  const labs = getInfo(labsInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={ResearchBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('research:research.titles.research')}</Title>
        <SubTitle>{t('research:research.titles.labs')}</SubTitle>
        {labs}
      </SectionContainer>
    </>
  );
};

export default Research;
