import styled from 'styled-components';

export const BannerContainer = styled.div`
  height: calc(100vw * 0.3125);
  width: 100vw;
  background-color: transparent;
  overflow: hidden;
  margin-left: calc((100vw - ${props => props.theme.maxWidth}) / -2 - ${props => props.theme.pagePadding} );
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    margin-left: -${props => props.theme.mobilePagePadding};
    margin-top: 0px;
  }
`;

export const Banner = styled.img` 
  width: 100vw;
`;
