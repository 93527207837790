import styled from 'styled-components';

export const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.secondary};
  padding: 10px 15px;
  border-radius: 5px;
  min-width: 150px;
  font-size: ${props => props.theme.normalFont};
  a {
    color: ${props => props.theme.secondary};
    text-decoration: none;
    &: hover {
      color: ${props => props.theme.secondary};
    }
  }
  &:hover {
    background-color: ${props => props.theme.third};
  }
  &:focus {
    outline: none;
  }
`;

export const NavButton = styled.button`
  width: 100%;
  text-align: left;
  font-size: ${props => props.theme.normalFont};
  padding: ${props => props.theme.navPadding};
  border: none;
  background-color: ${props => (props.active ? props.theme.third : 'transparent')};
  color: ${props => (props.active ? props.theme.secondary : props.theme.primary)};
  box-shadow: ${props => (props.active ? '0px 4px 2px rgba(68,106,101,.2)' : 'none')};

  line-height:${props => props.theme.normalFont};
  &:focus {
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  text-align: ${props => (props.textAlign ? props.textAlign : 'right')};
`;

export const CloseButton = styled.button`
  width: 30px;
  margin-right: 15px;
  margin-top: 5px;
  border: none;
  background-color: transparent;
  &::before {
    content: "✕" ;
  }
  &:focus {
    outline: none;
  }
`;
