import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import styled from "styled-components";
import {
  BannerContainer,
  Banner,
  SectionContainer,
  FlexContainer,
  CardBody,
  Title,
  SubTitle,
  Label,
  Text,
  HorizontalLine,
  Spinner,
} from "../../UI";
import CurrentCoursesBanner from "../../../imgs/banners/current_courses.jpg";
import ExternalLink from "../../Links/ExternalLink";
import { LanguageContext } from "../../../App";
import { getSemester } from "../../../utility";

const TitleCard = styled.div`
  border-radius: 5px;
  margin-bottom: 30px;
  border: 1px solid #ececec;
  background-color: #fff;
  width: 300px;
  margin-right: 20px;
  @media screen and (max-width: ${(props) => props.theme.maxWidth}) {
    margin-right: 0px;
  }
`;

const FilledCardTitle = styled.div`
  background: rgb(227, 233, 232);
  background: linear-gradient(
    45deg,
    rgba(227, 233, 232, 1) 0%,
    rgba(160, 205, 210, 1) 100%
  );
  width: calc(100% + 1px);
  margin-top: -2px;
  height: 90px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  a {
    color: ${(props) => props.theme.primary};
    &:hover {
      color: ${(props) => props.theme.third};
    }
  }
`;

const CurrentCourses = () => {
  const { t } = useTranslation();
  const { lg } = useContext(LanguageContext);
  const { currentSemester, lastSemester, lastSecondSemester } = getSemester();

  const GET_COURSES = gql`
    query($where:JSON){
      courses(where: $where){
        data {
          attributes {
            title_${lg}
            instructor_${lg}
            note_${lg}
            syllabus_link
            year
            semester
          }
        }
      }
    }`;

  // link(id:"5e4f5829fc3a2600fede19bf") {
  //   currentCourses_current_courses
  //   currentCourses_last_courses
  //   currentCourses_last_second_courses
  // }
  const { loading, error, data } = useQuery(GET_COURSES, {
    variables: { where: { year_gte: lastSecondSemester.year } },
  });

  if (error) {
    return `Error! ${error.message}`;
  }

  const filterBySemester = ({ data, year, semester }) => {
    if (data.year === year && data.semester === semester) {
      return data;
    }
    return null;
  };

  const CoursesFilterBySemester = (semesterObj) =>
    data &&
    data.courses
      .map((course) =>
        filterBySemester({
          data: course,
          year: semesterObj.year,
          semester: semesterObj.semester,
        })
      )
      .filter((course) => course !== null);

  const getInfo = (data) => {
    const info = Array.isArray(data)
      ? data.map((el) => (
          <TitleCard>
            <FilledCardTitle>
              <ExternalLink
                noPadding
                link={el.syllabus_link}
                linkText={el[`title_${lg}`]}
              />
            </FilledCardTitle>
            <CardBody>
              <Text>
                <Label>{t("titles.courses.current_courses.instructor")}</Label>
                {el[`instructor_${lg}`]}
              </Text>
              {el[`note_${lg}`] ? (
                <Text>
                  <Label>{t("titles.courses.current_courses.note")}</Label>
                  {el[`note_${lg}`]}
                </Text>
              ) : null}
            </CardBody>
          </TitleCard>
        ))
      : null;
    return info;
  };

  const currentCourses = getInfo(CoursesFilterBySemester(currentSemester));
  const lastCourses = getInfo(CoursesFilterBySemester(lastSemester));
  const lastSecondCourses = getInfo(
    CoursesFilterBySemester(lastSecondSemester)
  );

  return (
    <>
      <BannerContainer>
        <Banner src={CurrentCoursesBanner} />
      </BannerContainer>
      <HorizontalLine />
      {loading ? (
        <Spinner />
      ) : (
        <SectionContainer>
          <Title>{t("titles.courses.current_courses.current_courses")}</Title>
          <ExternalLink link="https://nol.ntu.edu.tw/nol/coursesearch/search_for_02_dpt.php?&coursename=&dptname=1420&dpt_sel=1000&yearcode=0&teachername=&selcode=-1&alltime=yes&week1=&week2=&week3=&week4=&week5=&week6=&allproced=yes&proced0=&proced1=&proced2=&proced3=&proced4=&procedE=&proced5=&proced6=&proced7=&proced8=&proced9=&procedA=&procedB=&procedC=&procedD=&allsel=yes&selCode1=&selCode2=&selCode3=&page_cnt=15&lang=EN" />
          {/* <SubTitle>
            {`${currentSemester.year}-${currentSemester.semester}`}
            <ExternalLink link={data.link.currentCourses_current_courses} />
          </SubTitle>
          <Text>{t("titles.courses.current_courses.course_search")}</Text>
          <FlexContainer>{currentCourses}</FlexContainer>
          {lastSemester.semester === 2 ? (
            <Title>{t("titles.courses.current_courses.recent_courses")}</Title>
          ) : null}
          <SubTitle>
            {`${lastSemester.year}-${lastSemester.semester}`}
            <ExternalLink link={data.link.currentCourses_last_courses} />
          </SubTitle>
          <FlexContainer>{lastCourses}</FlexContainer>
          {lastSemester.semester === 1 ? (
            <Title>{t("titles.courses.current_courses.recent_courses")}</Title>
          ) : null}
          <SubTitle>
            {`${lastSecondSemester.year}-${lastSecondSemester.semester}`}
            <ExternalLink link={data.link.currentCourse_last_second_courses} />
          </SubTitle> */}
          <FlexContainer>{lastSecondCourses}</FlexContainer>
        </SectionContainer>
      )}
    </>
  );
};

export default CurrentCourses;
