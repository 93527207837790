import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  Title,
  Label,
  Text,
  HorizontalLine,
  SectionContainer,
  GridSideBySide,
  Icon,
} from '../../UI';
import ContactInfoBanner from '../../../imgs/banners/contact_info.jpg';
import emailIcon from '../../../imgs/icons/email.png';
import phoneIcon from '../../../imgs/icons/phone.png';
import faxIcon from '../../../imgs/icons/fax.png';

const ContactInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerContainer>
        <Banner src={ContactInfoBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('contact_info:contact_info.titles.contact_info')}</Title>
        <GridSideBySide
          justifyContent="flex-start"
          marginRight={50}
        >
          <div>
            <Label>{t('contact_info:contact_info.chair.title')}</Label>
            {t('contact_info:contact_info.chair.name')}
            <Text>
              <Icon src={emailIcon} />
              {t('contact_info:contact_info.chair.email')}
            </Text>
          </div>
          <div>
            <Label>{t('contact_info:contact_info.administrative_staff.title')}</Label>
            {t('contact_info:contact_info.administrative_staff.name')}
            <Text>
              <Icon src={emailIcon} />
              {t('contact_info:contact_info.administrative_staff.email')}
            </Text>
            <Text>
              <Icon src={phoneIcon} />
              {t('contact_info:contact_info.phone')}
            </Text>
            <Text>
              <Icon src={faxIcon} />
              {t('contact_info:contact_info.fax')}
            </Text>
          </div>
        </GridSideBySide>
        <HorizontalLine />
        <GridSideBySide
          justifyContent="flex-start"
          marginRight={50}
        >
          <div>
            <Label>{t('contact_info:contact_info.webmaster.title')}</Label>
            {t('contact_info:contact_info.webmaster.name')}
            <Text>
              <Icon src={emailIcon} />
              {t('contact_info:contact_info.webmaster.email')}
            </Text>
          </div>
          <div>
            <p>
              <Label>{t('contact_info:contact_info.web_developer.title')}</Label>
              {t('contact_info:contact_info.web_developer.name')}
              <Text>
                <Icon src={emailIcon} />
                {t('contact_info:contact_info.web_developer.email')}
              </Text>
            </p>

            <p>
              <Label>{t('contact_info:contact_info.web_content_creater_photo_design.title')}</Label>
              {t('contact_info:contact_info.web_content_creater_photo_design.name')}
            </p>
            <p>
              <Label>{t('contact_info:contact_info.web_content_creater.title')}</Label>
              {t('contact_info:contact_info.web_content_creater.name')}
            </p>
            <p>
              <Label>{t('contact_info:contact_info.web_photographer.title')}</Label>
              {t('contact_info:contact_info.web_photographer.name')}
            </p>
          </div>
        </GridSideBySide>
      </SectionContainer>
    </>
  );
};

export default ContactInfo;
