import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled, {
  createGlobalStyle,
  ThemeProvider,
} from 'styled-components';
import theme from '../../config/styleConfig';
import {
  PageContainer,
  BackgroundImageTop,
  BackgroundImageBottom,
} from '../UI';
import Appbar from '../Appbar';
import Footer from '../Footer';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: "Helvetica regular","Roboto",Apple LiGothic Medium,Microsoft JhengHei,"蘋果儷中黑","微軟正黑體",sans-serif;
    overflow-x: hidden;
  }

  html {
    font-size: 62.5%;
  }
  
  p, div, button {
    color: ${props => props.theme.primary};
    font-size: ${props => props.theme.normalFont};
    line-height: 2.4rem;
  }
  
  p {
    word-break: normal;
    word-wrap: break-word;
  }
  
  a {
    color: ${props => props.theme.third};
    text-decorator: none;
    -webkit-tap-highlight-color: transparent;
    padding-left: 10px;
    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`;

const Main = styled.div``;

const Layout = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  const noBanner = pathname === '/404';

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Appbar />
        <PageContainer>
          <BackgroundImageTop noBanner={noBanner} />
          <Main>{children}</Main>
          <BackgroundImageBottom />
        </PageContainer>
        <Footer />
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(Layout);
