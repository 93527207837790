import React, { useState, useEffect } from "react";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider } from "@apollo/react-hooks";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import i18n from "./i18n";
import Layout from "./components/Layout";
import AppRoutes from "./routes";
import baseURL from "./config/url";

export const LanguageContext = React.createContext("zh_tw");

const App = () => {
  const [lg, setLg] = useState(localStorage.getItem("language") || "zh_tw");
  const uri = `${baseURL}/graphql`;

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: "Bearer " + process.env.REACT_APP_STRAPI_API_TOKEN,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: uri,
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const changeLanguage = (language) => setLg(language);

  useEffect(() => {
    const language = localStorage.getItem("language");
    i18n.changeLanguage(language);
  }, []);

  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <LanguageContext.Provider value={{ lg, changeLanguage }}>
            <Layout>
              <AppRoutes />
            </Layout>
          </LanguageContext.Provider>
        </BrowserRouter>
      </I18nextProvider>
    </ApolloProvider>
  );
};

export default App;
