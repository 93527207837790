import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  ContentContainer,
  FlexContainer,
  CardBody,
  Title,
  SubTitle,
  Label,
  Text,
  HorizontalLine,
  Icon,
  PhotoImage,
} from '../../UI';
import CLDCBanner from '../../../imgs/banners/cldc.jpg';
import ExternalLink from '../../Links/ExternalLink';
import PDFLink from '../../Links/PDFLink';
import WebsiteIcon from '../../../imgs/icons/website.png';
// import CLDCImg from '../../../imgs/resources/cldc/cldc9_poster.png';

const CLDC = () => {
  const { t } = useTranslation();

  const CLDC9PhotoAlbum = t('cldc:cldc.cldc.photo_album', { returnObjects: true });

  const CLDCPhotoAlbum = Array.isArray(CLDC9PhotoAlbum) ? CLDC9PhotoAlbum.map(el => (
    <CardBody><PhotoImage src={require(`../../../imgs/resources/cldc/${el}`)} width={720} /></CardBody>
  )) : null;

  const previousCLDC = t('cldc:cldc.cldc.previous', { returnObjects: true });

  const getpreviousCLDCInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <ContentContainer>
        <p>
          {el.title}
          {el.pdf_link ? <PDFLink link={require(`../../../pdf/resources/cldc_conference/${el.pdf_link}`)} /> : null}
          {el.external_link ? <ExternalLink link={el.external_link} /> : null}
          {el.no_link ? null : null}
        </p>
      </ContentContainer>
    )) : null;
    return info;
  };

const previousCLDCInfo = getpreviousCLDCInfo(previousCLDC);

  return (
    <>
      <BannerContainer>
        <Banner src={CLDCBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('cldc:cldc.titles.cldc')}</Title>
        <Text>{t('cldc:cldc.cldc.description')}</Text>
      </SectionContainer>
      <HorizontalLine />
      <SectionContainer>
        <div>
          <SubTitle>
            {t('cldc:cldc.cldc.title')}
          </SubTitle>
          {/*<div>
            <img src={CLDCImg} alt="CLDC 2019 poster" width={300} />
          </div>*/}
          <Text>
            <Label>{t('cldc:cldc.titles.website')}</Label>
            <a
              style={{ paddingLeft: 0 }}
              href={t('cldc:cldc.cldc.external_link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={WebsiteIcon} alt={t('cldc:cldc.cldc.external_link')}/>
            </a>
          </Text>
          <Text>
            <Label>{t('cldc:cldc.titles.date')}</Label>
            {t('cldc:cldc.cldc.date')}
          </Text>
          <Text>
            <Label>{t('cldc:cldc.titles.venue')}</Label>
            {t('cldc:cldc.cldc.venue')}
          </Text>
          {/* <Text>
            <Label>{t('cldc:cldc.titles.call_deadline')}</Label>
            {t('cldc:cldc.cldc.call_deadline')}
          </Text> */}
          <Text>
            <Label>{t('cldc:cldc.titles.meeting_email')}</Label>
            {t('cldc:cldc.cldc.meeting_email')}
          </Text>
          {/* <Text>
            <Label>{t('cldc:cldc.titles.program_book')}</Label>
            {t('cldc:cldc.cldc.program_book.title')}
          </Text> */}
        </div>
      </SectionContainer>
      <HorizontalLine />
      <SectionContainer>
        <SubTitle>{t('cldc:cldc.titles.previous')}</SubTitle>
        <FlexContainer>{CLDCPhotoAlbum}</FlexContainer>
        {previousCLDCInfo}
      </SectionContainer>
    </>
  );
};

export default CLDC;
