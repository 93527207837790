import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from '../UI';
import linkIcon from '../../imgs/icons/internal_link.png';

const InternalLink = ({ link, linkText, style, noPadding }) => (
  <Link
    to={link}
    style={style}
  >
    {linkText || <Icon src={linkIcon} />}
  </Link>
);

InternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default InternalLink;
