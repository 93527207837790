import styled from 'styled-components';

export const Card = styled.div`
  border: 1px solid lightgray;
  background-color: #fff;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  div {
    margin-right: 40px;
  }
  p {
    span {
      padding-right: 8px;
    }
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    flex-flow: column;
    div {
      margin-right: 0px;
    }
  }
`;

export const CardTitle = styled.p`
  font-size: 2rem;
`;

export const CardBody = styled.div`
  padding: 15px;
`;
