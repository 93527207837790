import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Banner,
  BannerContainer,
  SectionContainer,
  ListContainer,
  Title,
  SubTitle,
  TextTitle,
  Text,
  HorizontalLine,
} from '../../UI';
import FundingBanner from '../../../imgs/banners/funding.jpg';
import ExternalLink from '../../Links/ExternalLink';
import PDFLink from '../../Links/PDFLink';

const Funding = () => {
  const { t } = useTranslation();

  const conferenceSubsidyInfo = t('funding:funding.conference_participation_funds', { returnObjects: true });
  const scholarshipsInfo = t('funding:funding.scholarships', { returnObjects: true });
  const studyAbroadProgramInfo = t('funding:funding.study_abroad_program', { returnObjects: true });

  const getInfo = (data) => {
    const content = Array.isArray(data) ? data.map((el) => {
      return (
        <ListContainer>
          <TextTitle>
            {el.title}
            {el.external_link ? (
              <ExternalLink link={el.external_link} />
            ) : null }
            {el.pdf_link ? (
              <PDFLink link={require(`../../../doc/programs/funding/${el.pdf_link}`)} />
            ) : null }
            {el.no_link ? null : null}
          </TextTitle>
          <Text>{el.description ? el.description.split('\n').map(line => <p>{line}</p>) : null}</Text>
        </ListContainer>
      );
    }) : null;
    return content;
  };

  const conferenceSubsidy = getInfo(conferenceSubsidyInfo);
  const scholarships = getInfo(scholarshipsInfo);
  const studyAbroadProgram = getInfo(studyAbroadProgramInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={FundingBanner} />
      </BannerContainer>
      <SectionContainer style={{ width: '750px', marginLeft: 'auto', marginRight: 'auto' }}>
        <Title>{t('funding:funding.titles.funding')}</Title>
        <SubTitle>{t('funding:funding.titles.scholarships')}</SubTitle>
        {scholarships}
      </SectionContainer>
      <HorizontalLine />
      <SectionContainer style={{ width: '750px', marginLeft: 'auto', marginRight: 'auto' }}>
        <SubTitle>{t('funding:funding.titles.conference_participation_funds')}</SubTitle>
        {conferenceSubsidy}
      </SectionContainer>
      <HorizontalLine />
      <SectionContainer style={{ width: '750px', marginLeft: 'auto', marginRight: 'auto' }}>
        <SubTitle>{t('funding:funding.titles.study_abroad_program')}</SubTitle>
        {studyAbroadProgram}
      </SectionContainer>
    </>
  );
};

export default Funding;
