import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  SectionContainer,
  Title,
  Text,
  BannerContainer,
  Banner,
  IconImage,
  Exte
} from '../../UI';
import LICBanner from '../../../imgs/banners/lic.jpg';
import language from '../../../imgs/home/language.png';
import brain from '../../../imgs/home/cognition.png';
import infoIcon from '../../../imgs/home/information.png';
import ExternalLink from '../../Links/ExternalLink';

const LICContent = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  img {
    margin: 0 auto;
    display: block;
    height: 80px;
  }
  p {
    margin-right: 20px;
    margin-top: 30px;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    flex-direction: column;
    div {
      margin-top: 20px;
    }
  }
`;

const LIC = () => {
  const { t } = useTranslation();
  return (
    <>
      <BannerContainer>
        <Banner src={LICBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title style={{ paddingTop: 45 }}>{t('home:LIC_title')}</Title>
        <ExternalLink
            noPadding
            link={t('home:LIC_link')}
            linkText={t('home:LIC_link_title')}
        />
        <LICContent>
          <div>
            <IconImage src={brain} />
            <Text>
              {t('home:LIC_intro_1')}
            </Text>
          </div>
          <div>
            <IconImage src={language} />
            <Text>
              {t('home:LIC_intro_2')}
            </Text>
          </div>
          <div>
            <IconImage src={infoIcon} />
            <Text>
              {t('home:LIC_intro_3')}
            </Text>
          </div>
        </LICContent>
        <Text>{t('home:LIC_description')}</Text>
        <Text>{t('home:LIC_more')}</Text>
      </SectionContainer>
    </>
  );
};

export default LIC;
