import styled from 'styled-components';

const GridSideBySide = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  & > div {
    margin: 20px;
    margin-right: ${props => (props.marginRight ? props.marginRight : 20)}px;
  }
  .inverse0 {
    order: 1;
  } 
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    flex-direction: column;
    align-items: ${props => (props.mobileAlignItems ? props.mobileAlignItems : 'center')};
    margin-left: -${props => props.theme.mobilePagePadding};
    & > div {
      margin-right: 0;
      margin-left: ${props => (props.mobileMarginLeft ? props.mobileMarginLeft : 20)}px;
    }
    .inverse0, 
    .inverse1 {
      order: 1;
    }
  }
`;

export default GridSideBySide;
