import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  SubTitle,
  Card,
  CardTitle,
  Text,
} from '../../UI';
import InternationalStudentsBanner from '../../../imgs/banners/international_students.jpg';
import ExternalLink from '../../Links/ExternalLink';

const InternationalStudents = () => {
  const { t } = useTranslation();

  const administrationInfo = t('international_students:international_students.administrative_offices', { returnObjects: true });
  const languageInfo = t('international_students:international_students.Chinese_language_programs', { returnObjects: true });

  const getInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <Card>
        <div>
          <CardTitle>
            <ExternalLink
              noPadding
              link={el.external_link}
              linkText={el.title}
            />
          </CardTitle>
          { el.entry_requirements ? (
            <Text>
              <ExternalLink
                noPadding
                link={el.entry_requirements}
                linkText={t('international_students:international_students.titles.entry_requirements')}
              />
            </Text>
          ) : null }
          { el.scholarships ? (
            <p>
              <Text>
                <ExternalLink
                  noPadding
                  link={el.scholarships}
                  linkText={t('international_students:international_students.titles.scholarships')}
                />
              </Text>
              <Text>{el.description}</Text>
              <Text>{el.note}</Text>
            </p>
          ) : null}
        </div>
      </Card>
    )) : null;
    return info;
  };

  const administration = getInfo(administrationInfo);
  const language = getInfo(languageInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={InternationalStudentsBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('international_students:international_students.titles.international_students')}</Title>
        <SubTitle>{t('international_students:international_students.titles.administrative_offices')}</SubTitle>
        {administration}
      </SectionContainer>
      <SectionContainer>
        <SubTitle>{t('international_students:international_students.titles.Chinese_language_programs')}</SubTitle>
        {language}
      </SectionContainer>
    </>
  );
};

export default InternationalStudents;
