export const getDayOfWeek = ({ t, day }) => {
  const dayOfWeekValue = new Date(day).getDay();
  const dayOfWeek = t(`day_of_the_week.${dayOfWeekValue}`);
  return dayOfWeek;
};

const getLastSemester = ({ semester, semesterYear }) => {
  const lastSemesterYear = semester === 1 ? semesterYear - 1 : semesterYear;
  const lastSemester = semester === 1 ? semester + 1 : semester - 1;
  return { year: lastSemesterYear, semester: lastSemester };
};

const getLastSecondSemester = ({ semester, semesterYear }) => {
  const lastSecondSemesterYear = semesterYear - 1;
  const lastSecondSemester = semester;
  return { year: lastSecondSemesterYear, semester: lastSecondSemester };
};

export const getSemester = () => {
  const currentYear = new Date().getFullYear() - 1911;
  const currentMonth = new Date().getMonth() + 1;
  const semester = currentMonth >= 7 ? 1 : 2;
  const semesterYear = semester === 1 ? currentYear : currentYear - 1;
  const currentSemester = { year: semesterYear, semester };
  const lastSemester = getLastSemester({ semester, semesterYear });
  const lastSecondSemester = getLastSecondSemester({ semester, semesterYear });
  return { currentSemester, lastSemester, lastSecondSemester };
};


export default getDayOfWeek;
