import styled from 'styled-components';

export const HorizontalLine = styled.div`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export const CentralBarLine = styled.span`
  border: 0;
  height: 0;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(255, 255, 255, 1);
  margin-left: 1.4rem;
  margin-right: 0.4rem;
  padding-right: 10px;
`;
