import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Wrapper,
  Title,
  SubTitle,
  ContextualTitle,
  Text,
  HorizontalLine,
} from '../../UI';
import DegreeRequirementsBanner from '../../../imgs/banners/degree_requirements.jpg';
import ExternalLink from '../../Links/ExternalLink';
import PDFLink from '../../Links/PDFLink';

const DegreeRequirements = () => {
  const { t } = useTranslation();

  const MAothersInfo = t('degree_requirements:degree_requirements.ma_programs.others', { returnObjects: true });
  const PhDothersInfo = t('degree_requirements:degree_requirements.phd_programs.others', { returnObjects: true });

  const getOthers = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <Text>
        {el.description}
        {el.pdf_link ? <PDFLink link={require(`../../../pdf/courses/degree_requirements/${el.pdf_link}`)} /> : null}
        {el.external_link ? <ExternalLink link={el.external_link} /> : null}
        {el.no_link ? null : null}
      </Text>
    )) : null;
    return info;
  };

  const MAothers = getOthers(MAothersInfo);
  const PhDothers = getOthers(PhDothersInfo);

  const PhDSubFields = t('degree_requirements:degree_requirements.phd_programs.course_requirements.subfields.list', { returnObjects: true });
  return (
    <>
      <BannerContainer>
        <Banner src={DegreeRequirementsBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('degree_requirements:degree_requirements.titles.degree_requirements')}</Title>
      </SectionContainer>
      <SectionContainer>
        <Title>{t('degree_requirements:degree_requirements.titles.ma_programs')}</Title>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.total_credit_breakdown')}</SubTitle>
        <HorizontalLine />

        <Wrapper>
          <ContextualTitle>
            {t('degree_requirements:degree_requirements.titles.credits_for_required_courses')}
          </ContextualTitle>
          <p>{t('degree_requirements:degree_requirements.ma_programs.total_credit_breakdown.credits_for_required_courses')}</p>
        </Wrapper>

        <Wrapper>
          <ContextualTitle>
            {t('degree_requirements:degree_requirements.titles.credits_for_elective_courses')}
          </ContextualTitle>
          <p>{t('degree_requirements:degree_requirements.ma_programs.total_credit_breakdown.credits_for_elective_courses')}</p>
        </Wrapper>

        <Wrapper>
          <ContextualTitle>
            {t('degree_requirements:degree_requirements.titles.total_credits_for_graduation')}
          </ContextualTitle>
          <p>{t('degree_requirements:degree_requirements.ma_programs.total_credit_breakdown.total_credits_for_graduation')}</p>
        </Wrapper>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.core_requirements')}</SubTitle>
        <HorizontalLine />
        <p>{t('degree_requirements:degree_requirements.ma_programs.core_requirements')}</p>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.elective_requirements')}</SubTitle>
        <HorizontalLine />
        <p>{t('degree_requirements:degree_requirements.ma_programs.elective_requirements')}</p>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.others')}</SubTitle>
        <HorizontalLine />
        {MAothers}

      </SectionContainer>
      <SectionContainer>
        <Title>{t('degree_requirements:degree_requirements.titles.phd_programs')}</Title>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.total_credit_breakdown')}</SubTitle>
        <HorizontalLine />

        <Wrapper>
          <ContextualTitle>
            {t('degree_requirements:degree_requirements.titles.credits_for_required_courses')}
          </ContextualTitle>
          <p>{t('degree_requirements:degree_requirements.phd_programs.total_credit_breakdown.credits_for_required_courses')}</p>
        </Wrapper>

        <Wrapper>
          <ContextualTitle>
            {t('degree_requirements:degree_requirements.titles.credits_for_elective_courses')}
          </ContextualTitle>
          <p>{t('degree_requirements:degree_requirements.phd_programs.total_credit_breakdown.credits_for_elective_courses')}</p>
        </Wrapper>

        <Wrapper>
          <ContextualTitle>
            {t('degree_requirements:degree_requirements.titles.total_credits_for_graduation')}
          </ContextualTitle>
          <p>{t('degree_requirements:degree_requirements.phd_programs.total_credit_breakdown.total_credits_for_graduation')}</p>
        </Wrapper>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.course_requirements')}</SubTitle>
        <HorizontalLine />

        <p>{t('degree_requirements:degree_requirements.phd_programs.course_requirements.main_field')}</p>
        {PhDSubFields.map(el => <p>{el}</p>)}
        <p>{t('degree_requirements:degree_requirements.phd_programs.course_requirements.minimum_credits')}</p>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.qualifying_paper_requirements')}</SubTitle>
        <HorizontalLine />

        <p>
          {t('degree_requirements:degree_requirements.phd_programs.qualifying_paper_requirements.description')}
          <Text>
            {t('degree_requirements:degree_requirements.phd_programs.qualifying_paper_requirements.pdf_link.title')}
            <PDFLink
              link={t('degree_requirements:degree_requirements.phd_programs.qualifying_paper_requirements.pdf_link.pdf_link')}
            />
          </Text>
        </p>

        <SubTitle>{t('degree_requirements:degree_requirements.titles.others')}</SubTitle>
        <HorizontalLine />

        {PhDothers}

      </SectionContainer>
    </>
  );
};

export default DegreeRequirements;
