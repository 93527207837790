import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  SectionContainer,
  Title,
  Label,
  Card,
  CardTitle,
  PhotoImage,
  Text,
} from '../../UI';
import TOLBanner from '../../../imgs/banners/tol.jpg';
import ExternalLink from '../../Links/ExternalLink';

const TOL = () => {
  const { t } = useTranslation();
  const tolInfo = t('tol:tol.tol.list', { returnObjects: true });
  const getInfo = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <Card>
        <div>
          <PhotoImage src={require(`../../../imgs/resources/tol/${el.photo_name}`)} width={250} />
          <Text>
            <Label>
              (
              {t('tol:tol.titles.copyright')}
            </Label>
            {el.copyright}
              )
          </Text>
        </div>
        <div>
          <CardTitle>
            <ExternalLink
              noPadding
              link={el.external_link}
              linkText={(el.title)}
            />
          </CardTitle>
          <Text>{el.description}</Text>
        </div>
      </Card>
    )) : null;
    return info;
  };

  const tol = getInfo(tolInfo);

  return (
    <>
      <BannerContainer>
        <Banner src={TOLBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('tol:tol.titles.tol')}</Title>
        {tol}
      </SectionContainer>
    </>
  );
};

export default TOL;
