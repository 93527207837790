import React from 'react';
import styled from 'styled-components';
import DesktopNavigator from '../Navigation/DesktopNavigator';
import LogoLink from './LogoLink';
import HeaderLink from './HeaderLink';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

export const NavDesktopContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  background-color: #fff;
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

const NavDesktop = () => (
  <NavDesktopContainer>
    <HeaderContainer>
      <LogoLink />
      <HeaderLink />
    </HeaderContainer>
    <DesktopNavigator />
  </NavDesktopContainer>
);


export default NavDesktop;
