import React, { useState } from 'react';
import styled from 'styled-components';
import MobileNavigator from '../Navigation/MobileNavigator';
import LogoLink from './LogoLink';
import HeaderLink from './HeaderLink';
import {
  ButtonContainer,
  CloseButton,
} from '../UI';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  display: none;  
  z-index: 10;
  ${props => props.show && `
    display: block;
  `}
  @media screen and (min-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

const NavMobileContainer = styled.div`
  @media screen and (min-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 110px;
  z-index: 9;
  display: flex;
  flex-wrap: no-wrap;
  text-align: left;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
  padding: 10px 15px;
  background-color: ${props => props.theme.secondary};
  @media screen and (min-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

const Hamburger = styled.div`
 padding: 5px;
 cursor: pointer;
 position: absolute;
 right: 15px;
  div {
    border-bottom: 5px solid ${props => props.theme.third};
    border-radius: 5px;
    width: 30px;
    margin-bottom: 5px;
  }
  @media screen and (min-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

const SlidingMenu = styled.nav`
  position: fixed;
  top: 0;
  background-color: ${props => props.theme.secondary};
  width: 70vw;
  height: 100%;
  z-index: 11;
  transform: translateX(170vw); 
  transition: 0.3s ease-in-out;
  ${props => props.show && `
    transform: translateX(30vw); 
  `}
  @media screen and (min-width: ${props => props.theme.maxWidth}) {
    display: none;
  }
`;

const NavMobile = () => {
  const [menu, setMenu] = useState(false);
  const handleMenuClick = () => setMenu(!menu);
  const handleMenuCloseClick = () => setMenu(false);

  return (
    <>
      <Overlay
        show={menu}
        onClick={handleMenuClick}
      />
      <NavMobileContainer>
        <HeaderContainer>
          <LogoLink />
          <Hamburger onClick={handleMenuClick}>
            <div />
            <div />
            <div />
          </Hamburger>
          <HeaderLink />
        </HeaderContainer>
        <SlidingMenu show={menu}>
          <ButtonContainer>
            <CloseButton
              type="button"
              onClick={handleMenuCloseClick}
            />
          </ButtonContainer>
          <MobileNavigator
            handleMenuCloseClick={handleMenuCloseClick}
          />
        </SlidingMenu>
      </NavMobileContainer>
    </>
  );
};

export default NavMobile;
