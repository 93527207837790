import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import navigator from '../../config/navConfig';
import { LanguageContext } from '../../App';

const Nav = styled.ul`
  list-style-type: none;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
  max-width: ${props => props.theme.maxWidth};
  text-align: right;
  background-color: ${props => props.theme.secondary};
  li {
    cursor: pointer;
    a {
      color: ${props => props.theme.primary};
      &.active {
        color: ${props => props.theme.secondary};
      }
    }
    &:hover{
      background-color: ${props => props.theme.third};
    }
  }
`;

const LinkItem = styled.div`
  display: flex;
  text-align: center;
  padding: 12px;
  &:hover {
    color: ${props => props.theme.secondary};
    a {
      color: ${props => props.theme.secondary};
    }
  }
  a {
    text-decoration: none;
    padding: 0;
  }
  ${props => props.activeCategory && `
    color: ${props.theme.secondary};
  `}
  ${props => props.activePath && `
    color: ${props.theme.secondary};
    background-color: ${props.theme.third};
  `}
`;

const Dropdown = styled.li`
  display: inline-block;
  ${props => props.active && `
    background-color: red;
  `}
`;

const SubMenu = styled.div`
  display: none;
  position: absolute;
  background-color: ${props => props.theme.secondary};
  min-width: 180px;
  box-shadow: 4px 4px 16px rgba(0,0,0,0.2);
  z-index: 1;
  li {
    &:hover {
      a, div {
        color: ${props => props.theme.secondary};
      }
    }
  }
  &.open {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }
  a, div{
    flex-grow: 1;
    text-decoration: none;
    padding: 15px 20px;
    display: block;
    text-align: left;
  }
`;

const NavContainer = styled.div`
  background-color: ${props => props.theme.secondary};
  text-align: right;
`;

const DesktopNavigator = ({ history }) => {
  const { t, i18n } = useTranslation();
  const { location: { pathname } } = history;
  const [activeCategory, setActiveCategory] = useState(null);
  const [activePath, setActivePath] = useState(null);
  const dashToUnderline = string => string.replace(/-/g, '_');
  const { changeLanguage } = useContext(LanguageContext);

  const handleSubMenu = (state, category) => {
    const value = state === 'open' ? category : null;
    setActiveCategory(value);
  };

  const handleLanguageChange = (language) => {
    setActiveCategory(null);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    changeLanguage(language);
  };

  useEffect(() => {
    const activatedPath = pathname.split('/')[1];
    setActivePath(activatedPath);
  }, [pathname]);

  const Categories = (navigatorItems) => {
    const Category = category => (
      <li>
        <LinkItem
          activePath={activePath === category.name}
          activeCategory={activeCategory === category}
        >
          <Link
            to={`/${category.name}`}
            className={activePath === category.name ? 'active' : 'inactive'}
          >
            {t(`navigation.${dashToUnderline(category.name)}`)}
          </Link>
        </LinkItem>
      </li>
    );

    const CategoryWithSubNav = (category) => {
      const activeSubMenu = activeCategory === category ? 'open' : 'close';
      if (category.name === 'languages') {
        return (
          <Dropdown
            onMouseEnter={() => handleSubMenu('open', category)}
            onMouseLeave={() => handleSubMenu('close', category)}
          >
            <LinkItem activeCategory={activeCategory === category}>
              {t(`navigation.${dashToUnderline(category.name)}`)}
            </LinkItem>
            <SubMenu
              className={`dropdown-content ${activeSubMenu}`}
            >
              {category.subNav.map(subCategory => (
                <li key={subCategory}>
                  <LinkItem style={{ padding: '5px 0' }}>
                    <div
                      onClick={() => handleLanguageChange(subCategory)}
                    >
                      {t(`navigation.${dashToUnderline(subCategory)}`)}
                    </div>
                  </LinkItem>
                </li>
              ))}
            </SubMenu>
          </Dropdown>
        );
      }
      return (
        <Dropdown
          onMouseEnter={() => handleSubMenu('open', category)}
          onMouseLeave={() => handleSubMenu('close', category)}
        >
          <LinkItem
            activeCategory={activeCategory === category}
            activePath={activePath === category.name}
          >
            {t(`navigation.${category.name}`)}
          </LinkItem>
          <SubMenu
            className={`dropdown-content ${activeSubMenu}`}
          >
            {category.subNav.map(subCategory => (
              <li key={subCategory}>
                <LinkItem style={{ padding: '5px 0' }}>
                  <Link
                    to={`/${category.name}/${subCategory}`}
                    onClick={() => handleSubMenu('close')}
                  >
                    {t(`navigation.${dashToUnderline(subCategory)}`)}
                  </Link>
                </LinkItem>
              </li>
            ))}
          </SubMenu>
        </Dropdown>
      );
    };

    return navigatorItems.map(category => (
      category.subNav
        ? CategoryWithSubNav(category)
        : Category(category)
    ));
  };

  return (
    <NavContainer>
      <Nav>{Categories(navigator)}</Nav>
    </NavContainer>
  );
};

export default withRouter(DesktopNavigator);
