import titleIcon from '../../imgs/icons/title.png';
import educationIcon from '../../imgs/icons/education.png';
import targetIcon from '../../imgs/icons/target.png';
import websiteIcon from '../../imgs/icons/website.png';
import cvIcon from '../../imgs/icons/cv.png';
import emailIcon from '../../imgs/icons/email.png';
import phoneIcon from '../../imgs/icons/phone.png';
import faxIcon from '../../imgs/icons/fax.png';
import officeHourIcon from '../../imgs/icons/office_hour.png';

const fieldsWithIcon = {
  title: titleIcon,
  education: educationIcon,
  phone: phoneIcon,
  fax: faxIcon,
  email: emailIcon,
  research_interests: targetIcon,
  website: websiteIcon,
  cv: cvIcon,
  office_hour: officeHourIcon,
};

export default fieldsWithIcon;
