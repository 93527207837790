import React, {
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  ButtonContainer,
  NavButton,
} from '../UI';
import navigator from '../../config/navConfig';
import { LanguageContext } from '../../App';

// const hoverEffect = css`
//   background-color: ${props => props.theme.third};
//   color: ${props => props.theme.secondary};
//   transition-duration: ${props => props.theme.transitionDuration};
// `;

const linkStyling = css`
  text-decoration: none;
  color: ${props => props.theme.primary};
`;

const NavContainer = styled.nav`
  background-color: ${props => props.theme.secondary};
`;

const Nav = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
  position: relative;
  z-index: 3;
  line-height: ${props => props.theme.normalFont};
  font-size: ${props => props.theme.normalFont};
  max-width: ${props => props.theme.maxWidth};
`;

// @media screen and (min-width: ${props => props.theme.maxWidth}) {
//   flex-direction: row;
//   flex-wrap: nowrap;
// }

const Category = styled.div`
  cursor: pointer;
  text-align: left;
  border-bottom: 1px solid lightgray;
  & > a {
    ${linkStyling}
    width: 100%;
    display: inline-block;
    padding-left: 0;
    font-size: ${props => props.theme.normalFont};
  }
`;

// @media screen and (min-width: ${props => props.theme.maxWidth}) {
//   border: none;
//   &:hover {
//     ${hoverEffect}
//     nav {
//       display: flex;
//     }
//     button {
//       color: ${props => props.theme.secondary};
//     }
//   }
//   & > a {
//     padding: 0;
//   }
// }

const SubNav = styled.nav`
  display: none;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: ${props => props.theme.secondary};
  justify-content: flex-end;
  a, button {
    ${linkStyling}
    padding: 8px;
    padding-left: 35px;
  }
  button {
    margin: 0;
  }
  ${props => props.show && `
    display: flex;
  `};  
`;
// @media screen and (min-width: ${props => props.theme.maxWidth}) {
//   position: absolute;
//   min-width: 200px;
//   a, p {
//     padding: ${props => props.theme.navPadding};
//   }
//   a:hover, p:hover {
//     ${hoverEffect}
//   }
// }


const Navigation = ({ handleMenuCloseClick }) => {
  const { t, i18n } = useTranslation();

  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const { changeLanguage } = useContext(LanguageContext);

  const handleLanguageClick = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    changeLanguage(language);
    if (handleMenuCloseClick) {
      handleMenuCloseClick();
    }
  };

  const handleMainCategoryClick = mainCategory => setActiveSubMenu(mainCategory);

  const Categories = () => navigator.map((category) => {
    const dashToUnderline = string => string.replace(/-/g, '_');

    const renderSubMenu = () => (
      <SubNav show={activeSubMenu === category.name}>
        {category.subNav.map(sub => (
          <NavLink
            key={`${category.name}_${sub}`}
            to={`/${category.name}/${sub}`}
            exact
            onClick={handleMenuCloseClick}
          >
            {t(`navigation.${dashToUnderline(sub)}`)}
          </NavLink>
        ))}
      </SubNav>
    );

    const renderLanguageSubMenu = () => (
      <SubNav show={activeSubMenu === category.name}>
        {category.subNav.map(sub => (
          <ButtonContainer
            key={sub}
            textAlign="left"
          >
            <NavButton
              type="button"
              onClick={() => handleLanguageClick(sub)}
            >
              {t(`navigation.${sub}`)}
            </NavButton>
          </ButtonContainer>
        ))}
      </SubNav>
    );

    const CategoryWithSubNav = () => {
      const renderSubNav = category.name === 'languages' ? renderLanguageSubMenu() : renderSubMenu();
      return (
        <>
          <NavButton
            type="button"
            onClick={() => handleMainCategoryClick(category.name)}
          >
            {t(`navigation.${dashToUnderline(category.name)}`)}
          </NavButton>
          {renderSubNav}
        </>
      );
    };

    const CategroyWithoutSubNav = () => (
      <NavLink
        to={`/${category.name}`}
        exact
        onClick={handleMenuCloseClick}
      >
        <NavButton type="button">
          {t(`navigation.${dashToUnderline(category.name)}`)}
        </NavButton>
      </NavLink>
    );

    return (
      <li key={category.name}>
        <Category className="category">
          {category.subNav ? <CategoryWithSubNav /> : <CategroyWithoutSubNav />}
        </Category>
      </li>
    );
  });

  return (
    <NavContainer>
      <Nav>
        {Categories(navigator)}
      </Nav>
    </NavContainer>
  );
};

Navigation.propTypes = {
  handleMenuCloseClick: PropTypes.func.isRequired,
};

export default Navigation;
