import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  Banner,
  Button,
  SectionContainer,
  Title,
  SubTitle,
  TextTitle,
  Text,
  Tabs,
  Tab,
  TabContent,
  HorizontalLine,
  Workshop,
  WorkshopAbstract,
  WorkshopBlock,
  WorkshopBreak,
  WorkshopCard,
  PhotoImage,
  WorkshopSpeaker,
  FlexContainer,
  CardBody,
} from '../../UI';
import NTUEBanner from '../../../imgs/banners/ntue_workshop.jpg';
import ExternalLink from '../../Links/ExternalLink';
import PDFLink from '../../Links/PDFLink';
import People from '../../Share/People';

// define what you want to show on the page
const NTÜ = () => {
  const { t } = useTranslation();

  const getSource = (fileName) => {
    return require('../../../pdf/resources/ntue/' + fileName);
  };

  const photoAlbumInfo = t('ntue:photo_album', { returnObjects: true });

  const photoAlbum = Array.isArray(photoAlbumInfo) ? photoAlbumInfo.map(el => (
    <CardBody><PhotoImage src={require(`../../../imgs/resources/ntue/photo_album/${el}`)} width={720} /></CardBody>
  )) : null;

  const folder = 'resources/ntue';
  const [activeTabContent, setActiveTabContent] = useState('introduction');
  const handleTabOnClick = value => setActiveTabContent(value);

  // get program day
  const getProgram_day = (data) => {
    const info = Array.isArray(data) ? data.map(el => (
      <Workshop>
        <WorkshopBlock>
          {el.time ? <p>{el.time}</p> : null}
        </WorkshopBlock>
        <div>
          {el.break ? (<WorkshopBreak>{el.break}</WorkshopBreak>) : null}
        </div>
        <div>
          {el.talk ? (
            <WorkshopBreak>
              <Title>
                {el.talk}
              </Title>
              <TextTitle>
                {el.host}
              </TextTitle>
            </WorkshopBreak>
          ) : null}
        </div>
        <div>
          {el.speaker ? (
            <WorkshopCard>
              <People
                resource="ntue"
                data={el.speaker}
              />
              <WorkshopSpeaker>
                <div>
                  <SubTitle>
                    {el.speech}
                    {el.slides_file ? (
                      el.slides_file.map(file => (
                        <PDFLink
                          link={getSource(file)}
                          linkText={`(${t('ntue:ntue.titles.slides')})`}
                        />
                      ))
                      
                    ) : null}
                    {el.slides_url ? (
                      el.slides_url.map(url => (
                        <ExternalLink
                          link={url}
                          linkText={`(${t('ntue:ntue.titles.slides')})`}
                        />
                      ))
                    ) : null}
                  </SubTitle>
                </div>
              </WorkshopSpeaker>
              <WorkshopSpeaker>
                <div>
                  {el.host ? null : (
                    <WorkshopAbstract>
                      {el.abstract.split('\n').map(line => <p>{line}</p>)}
                    </WorkshopAbstract>
                  )}
                </div>
              </WorkshopSpeaker>
            </WorkshopCard>
          ) : null }
        </div>
        <div>
          {el.discussion ? (
            <WorkshopCard>
              <WorkshopSpeaker>
                <div>
                  <SubTitle>
                    {el.discussion}
                    {el.slides_file ? (
                      el.slides_file.map(file => (
                        <PDFLink
                          link={getSource(file)}
                          linkText={`(${t('ntue:ntue.titles.slides')})`}
                        />
                      ))
                      
                    ) : null}
                    {el.slides_url ? (
                      el.slides_url.map(url => (
                        <ExternalLink
                          link={url}
                          linkText={`(${t('ntue:ntue.titles.slides')})`}
                        />
                      ))
                    ) : null}
                  </SubTitle>
                </div>
              </WorkshopSpeaker>

              <WorkshopAbstract>
                {el.description.split('\n').map(line => <p>{line}</p>)}
              </WorkshopAbstract>
              {el.host ? (
                <People
                  resource="ntue"
                  data={el.host}
                />
              ) : null}
              {el.forum_speaker ? (
                <People
                  resource="ntue"
                  data={el.forum_speaker}
                />
              ) : null}
              {el.discusser ? (
                <People
                  resource="ntue"
                  data={el.discusser}
                />
              ) : null}
            </WorkshopCard>
          ) : null }
        </div>
      </Workshop>
    )) : null;

    return (
      <SectionContainer>
        {info}
      </SectionContainer>
    );
  };

  // get program info of day 1
  const Program_day_1 = t('ntue:day_1', { returnObjects: true });
  const Program_day_1Info = getProgram_day(Program_day_1);

  // get program info of day 2
  const Program_day_2 = t('ntue:day_2', { returnObjects: true });
  const Program_day_2Info = getProgram_day(Program_day_2);

  return (
    <>
      <BannerContainer>
        <Banner src={NTUEBanner} />
      </BannerContainer>
      <SectionContainer>
        <Title>{t('ntue:ntue.ntue.title')}</Title>
        <Title>{t('ntue:2nd_ai_forum.title')}</Title>
        <Text>{t('ntue:ntue.titles.theme')}</Text>
        <Text>{t('ntue:ntue.titles.date')}</Text>
        <Text>{t('ntue:ntue.titles.venue')}</Text>
        <Text>{t('ntue:ntue.titles.co_orgnizer')}</Text>
        <Button>
          <ExternalLink
            noPadding
            link={t('ntue:ntue.titles.registration_link')}
            linkText={t('ntue:ntue.titles.registration')}
          />
        </Button>
      </SectionContainer>
      <HorizontalLine />
      <SectionContainer>
        <Tabs>
          <Tab
            active={activeTabContent === 'introduction'}
            onClick={() => handleTabOnClick('introduction')}
          >
            <SubTitle>{t('ntue:ntue.titles.introduction')}</SubTitle>
          </Tab>
          <Tab
            active={activeTabContent === 'day_1'}
            onClick={() => handleTabOnClick('day_1')}
          >
            <SubTitle>{t('ntue:ntue.titles.day1')}</SubTitle>
          </Tab>
          <Tab
            active={activeTabContent === 'day_2'}
            onClick={() => handleTabOnClick('day_2')}
          >
            <SubTitle>{t('ntue:ntue.titles.day2')}</SubTitle>
          </Tab>
        </Tabs>
        <TabContent show={activeTabContent === 'introduction'}>
          <TextTitle>{t('ntue:ntue.ntue.title')}</TextTitle>
          <Text>{t('ntue:ntue.ntue.description')}</Text>
          <PhotoImage src={require(`../../../imgs/${folder}/${t('ntue:ntue.ntue.photo_name')}`)} width={720} />
          <TextTitle>{t('ntue:2nd_ai_forum.title')}</TextTitle>
          <Text>{t('ntue:2nd_ai_forum.description')}</Text>
          <PhotoImage src={require(`../../../imgs/${folder}/${t('ntue:2nd_ai_forum.photo_name')}`)} width={720} />
        </TabContent>
        <TabContent show={activeTabContent === 'day_1'}>
          <SubTitle>{t('ntue:ntue.ntue.venue')}</SubTitle>
          {Program_day_1Info}
        </TabContent>
        <TabContent show={activeTabContent === 'day_2'}>
          <SubTitle>{t('ntue:2nd_ai_forum.venue')}</SubTitle>
          {Program_day_2Info}
        </TabContent>
      </SectionContainer>
      <HorizontalLine />
      <SectionContainer>
        <SubTitle>{t('ntue:ntue.titles.photo_album')}</SubTitle>
        <FlexContainer>{photoAlbum}</FlexContainer>
      </SectionContainer>
    </>
  );
};

export default NTÜ;
