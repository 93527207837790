import common from './en/common.json';
import common_zh_tw from './zh_tw/common.json';
import location from './en/about/location.json';
import location_zh_tw from './zh_tw/about/location.json';
import contact_info from './en/about/contact_info.json';
import contact_zh_tw from './zh_tw/about/contact_info.json';
import home from './en/home';
import home_zh_tw from './zh_tw/home';
import ma_programs from './en/programs/ma.json';
import phd_programs from './en/programs/phd.json';
import international_students from './en/programs/international_students.json';
import funding from './en/programs/funding.json';
import ma_programs_zh_tw from './zh_tw/programs/ma.json';
import phd_programs_zh_tw from './zh_tw/programs/phd.json';
import international_students_zh_tw from './zh_tw/programs/international_students.json';
import funding_zh_tw from './zh_tw/programs/funding.json';
import newsletters from './en/announcement/newsletters.json';
import newsletters_zh_tw from './zh_tw/announcement/newsletters.json';
import degree_requirements from './en/courses/degree_requirements';
import degree_requirements_zh_tw from './zh_tw/courses/degree_requirements';
import research from './en/research';
import research_zh_tw from './zh_tw/research/index.json';
import online_courses from './en/resources/online_courses.json';
import online_courses_zh_tw from './zh_tw/resources/online_courses.json';
import cldc from './en/resources/cldc.json';
import cldc_zh_tw from './zh_tw/resources/cldc.json';
import corpora from './en/resources/corpora.json';
import corpora_zh_tw from './zh_tw/resources/corpora.json';
import ntue from './en/resources/ntue.json';
import ntue_zh_tw from './zh_tw/resources/ntue.json';
import tol from './en/resources/tol.json';
import tol_zh_tw from './zh_tw/resources/tol.json';
import facilities from './en/resources/facilities.json';
import facilities_zh_tw from './zh_tw/resources/facilities.json';
import rogil from './en/resources/rogil.json';
import rogil_zh_tw from './zh_tw/resources/rogil.json';


const translations = {
  en: {
    translation: common,
    location,
    contact_info,
    home,
    ma_programs,
    phd_programs,
    international_students,
    funding,
    degree_requirements,
    research,
    online_courses,
    cldc,
    corpora,
    ntue,
    tol,
    facilities,
    rogil,
    newsletters,
  },
  zh_tw: {
    translation: common_zh_tw,
    location: location_zh_tw,
    contact_info: contact_zh_tw,
    home: home_zh_tw,
    ma_programs: ma_programs_zh_tw,
    phd_programs: phd_programs_zh_tw,
    international_students: international_students_zh_tw,
    funding: funding_zh_tw,
    degree_requirements: degree_requirements_zh_tw,
    research: research_zh_tw,
    online_courses: online_courses_zh_tw,
    cldc: cldc_zh_tw,
    corpora: corpora_zh_tw,
    ntue: ntue_zh_tw,
    tol: tol_zh_tw,
    facilities: facilities_zh_tw,
    rogil: rogil_zh_tw,
    newsletters: newsletters_zh_tw,
  },
};

export default translations;
